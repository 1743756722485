import { Component, HostListener, ViewChild } from '@angular/core';
import { ICoding } from 'src/app/interfaces/ICoding';
import { AddPatientService } from 'src/app/services/addpatient.service';
import { PersonalInfoComponent } from './personal-info/personal-info.component';
import { IdentifiersComponent } from './identifiers/identifiers.component';
import { AddressComponent } from './address/address.component';
import { IAddress } from 'src/app/interfaces/IAddress';
import { Router } from '@angular/router';
import { IAddPatient } from 'src/app/interfaces/Add/IAddPatient';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormGroup,
} from '@angular/forms';
import { InsuranceComponent } from './insurance/insurance.component';
import { MatStepper } from '@angular/material/stepper';
import { IPatientDocument } from 'src/app/interfaces/IPatientDocument';
import { IDoctor } from '../../../interfaces/IDoctor';
import Swal from 'sweetalert2';
import { Subject } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { NzSelectOptionInterface } from 'ng-zorro-antd/select';
import { EmergencyContactsComponent } from './emergency-contacts/emergency-contacts.component';
import { IEmergencyContact } from 'src/app/interfaces/IPatientsEmergencyContact';
import { MedicalInfoComponent } from './medical-info/medical-info.component';
import { PatientService } from 'src/app/services/patient.service';
import {
  IExistingDocument,
  IExistingPatient,
} from 'src/app/interfaces/IExistingPatient';
import { TranslateService } from '@ngx-translate/core';
import { CanLeaveGuard } from 'src/app/guards/can-leave.guard';

@Component({
  selector: 'app-register-patient',
  templateUrl: './register-patient.component.html',
  styleUrls: ['./register-patient.component.css'],
})
export class RegisterPatientComponent {
  @HostListener('window:beforeunload', ['$event'])
  handleBeforeUnload(event: BeforeUnloadEvent) {
    event.preventDefault();
    event.returnValue = '';
  }

  // Form
  patientForm: FormGroup;
  formSubmitted: boolean = false;

  // Loading
  private destroy$ = new Subject<void>();
  isLoading = false;

  // Value Sets
  public bloodTypesList: ICoding[] = [];
  public gendersList: ICoding[] = [];
  public countriesList: ICoding[] = [];
  public doctorList: IDoctor[] = [];

  public insuranceList: ICoding[] = [];
  public immobilities: ICoding[] = [];
  public immobilityList: NzSelectOptionInterface[] = [];

  public doctors: NzSelectOptionInterface[] = [];

  public placeOfBirthList: ICoding[] = [];
  public closestRelativesList: ICoding[] = [];
  public educationLevelList: ICoding[] = [];
  public familyStatusList: ICoding[] = [];
  public sourceOfIncomeList: ICoding[] = [];
  public religionList: ICoding[] = [];

  // Step Components
  @ViewChild(PersonalInfoComponent) personalInfoComp!: PersonalInfoComponent;
  @ViewChild(IdentifiersComponent) identifiersComp!: IdentifiersComponent;
  @ViewChild(AddressComponent) addressComp!: AddressComponent;
  @ViewChild(EmergencyContactsComponent)
  emergencyContacts!: EmergencyContactsComponent;
  @ViewChild(InsuranceComponent) insuranceComp!: InsuranceComponent;
  @ViewChild(MedicalInfoComponent) medicalComp!: MedicalInfoComponent;

  constructor(
    private readonly addPatient: AddPatientService,
    private readonly router: Router,
    private formBuilder: FormBuilder,
    private readonly patientService: PatientService,
    private readonly translate: TranslateService,
    private canLeaveGuard: CanLeaveGuard
  ) {
    this.patientForm = this.formBuilder.group({
      personalInfo: this.formBuilder.group({
        // Define personal info form controls and validators here
      }),
      identifiers: this.formBuilder.group({
        // Define identifiers form controls and validators here
      }),
      address: this.formBuilder.group({
        // Define address form controls and validators here
      }),
      emergencyContacts: this.formBuilder.group({
        // Define identifiers form controls and validators here
      }),
      insurance: this.formBuilder.group({
        // Define insurance form controls and validators here
      }),
      medical: this.formBuilder.group({
        // Define insurance form controls and validators here
      }),
    });
  }

  ngOnInit() {
    this.isLoading = false;
    this.addPatient.getPatientsDropDown().subscribe({
      next: (response) => {
        this.bloodTypesList = response?.data?.cachedData?.bloodTypes;
        this.gendersList = response?.data?.cachedData?.genders;
        this.countriesList = response?.data?.cachedData?.countries;
        this.doctorList = response?.data?.doctors;

        this.placeOfBirthList = response?.data?.cachedData?.countries;
        this.closestRelativesList =
          response?.data?.cachedData?.closestRelatives;
        this.educationLevelList = response?.data?.cachedData?.educationLevels;
        this.familyStatusList = response?.data?.cachedData?.familyStatuses;
        this.sourceOfIncomeList = response?.data?.cachedData?.sourceOfIncome;
        this.religionList = response?.data?.cachedData?.religions;

        this.insuranceList = response?.data?.cachedData?.insurances;
        this.immobilities = response?.data?.cachedData?.immobility;

        this.immobilityList = this.immobilities.map((d) => {
          return { label: d.name, value: d.id };
        });

        this.doctors = this.doctorList.map((d) => {
          return { label: d.fullName, value: d.id };
        });
      },
      error: (err) => {
        console.log(err);
      },
    });
  }

  async savePatientForm() {
    this.formSubmitted = true;
    this.isLoading = true;

    const personalInfo = this.personalInfoComp?.personalInfoForm?.value;

    const patientObj: IAddPatient = {
      firstName: personalInfo?.['firstName'],
      lastName: personalInfo?.['lastName'],
      dateOfBirth: personalInfo?.['dateOfBirth'],
      phone: personalInfo?.['phone']?.e164Number || '',
      email: this?.identifiersComp?.identifiersForm?.value['email'],
      genderId: Number(personalInfo?.['gender']) || undefined,
      occupation: personalInfo?.['occupation'] || null,
      dateOfAdmission: personalInfo?.['dateOfAdmission'] || null,
      placeOfBirthDistrict: personalInfo?.['districtOfBirth'] || null,
      placeOfBirthId: personalInfo?.['placeOfBirthId'] || null,
      closestRelativesId: personalInfo?.['closestRelativesId'] || null,
      closestRelativesOther: personalInfo?.['closestRelativesOther'] || null,
      educationLevelId: personalInfo?.['educationLevelId'] || null,
      educationLevelOther: personalInfo?.['educationLevelOther'] || null,
      familyStatusId: personalInfo?.['familyStatusId'] || null,
      familyStatusOther: personalInfo?.['familyStatusOther'] || null,
      sourceOfIncomeId: personalInfo?.['sourceOfIncomeId'] || null,
      religionId: personalInfo?.['religionId'] || null,
      religionOther: personalInfo?.['religionOther'] || null,
      amountOfIncome: personalInfo?.['amountOfIncome'] || null,
      registrationAgentId: personalInfo?.['registrationAgentId'] || null,
    };

    if (this?.addressComp?.addressForm?.value) {
      const addressObj: IAddress = {
        street: this.addressComp.addressForm?.value['street'] || undefined,
        streetNumber:
          this.addressComp.addressForm?.value['streetNumber'] || undefined,
        town: this.addressComp.addressForm?.value['town'] || undefined,
        postCode: this.addressComp.addressForm?.value['postcode'] || undefined,
        district: this.addressComp.addressForm?.value['district'] || undefined,
        countryId:
          Number(this.addressComp.addressForm?.value['country']) || undefined,
        apartmentNumber:
          this.addressComp.addressForm?.value['apartmentNumber'] || undefined,
      };

      patientObj.addAddressDto = addressObj;
    }

    const documentsObj: IPatientDocument[] = [];

    if (
      this?.identifiersComp?.identifiersForm?.value['nationalId'] &&
      this?.identifiersComp?.identifiersForm?.value['nationalIdCountry']
    ) {
      documentsObj.push({
        documentTypeId: 1,
        documentNumber:
          this.identifiersComp.identifiersForm.value['nationalId'],
        documentCountryIssuedId: Number(
          this.identifiersComp.identifiersForm.value['nationalIdCountry']
        ),
      });
    }

    if (
      this?.identifiersComp?.identifiersForm?.value['passport'] &&
      this?.identifiersComp?.identifiersForm?.value['passportCountry']
    ) {
      documentsObj.push({
        documentTypeId: 2,
        documentNumber: this.identifiersComp.identifiersForm.value['passport'],
        documentCountryIssuedId: Number(
          this.identifiersComp.identifiersForm.value['passportCountry']
        ),
      });
    }

    if (this?.identifiersComp?.identifiersForm?.value['arc']) {
      documentsObj.push({
        documentTypeId: 3,
        documentNumber: this?.identifiersComp.identifiersForm.value['arc'],
      });
    }

    if (documentsObj.length !== 0) {
      patientObj.documents = documentsObj;
    }

    const emergencyContactsObj: IEmergencyContact[] =
      (
        this.emergencyContacts?.emergencyContactsForm?.get(
          'contacts'
        ) as FormArray
      )?.controls?.map((contact) => {
        const contactFormGroup = contact as FormGroup;
        const identifierType = contactFormGroup.get('identifierType')?.value;
        const documentTypeId =
          identifierType === 'nationalId'
            ? 1
            : identifierType === 'passport'
            ? 2
            : 3;

        const addressData = {
          street: contactFormGroup.get('street')?.value,
          town: contactFormGroup.get('town')?.value,
          postCode: contactFormGroup.get('postcode')?.value,
          district: contactFormGroup.get('district')?.value,
          countryId: contactFormGroup.get('country')?.value
            ? Number(contactFormGroup.get('country')?.value)
            : undefined,
          streetNumber: contactFormGroup.get('streetNumber')?.value,
          apartmentNumber: contactFormGroup.get('apartmentNumber')?.value,
        };

        return {
          firstName: contactFormGroup.get('firstName')?.value,
          lastName: contactFormGroup.get('lastName')?.value,
          closestRelativeId: contactFormGroup.get('closestRelativeId')?.value,
          closestRelativeOther: contactFormGroup.get('closestRelativeOther')
            ?.value,
          occupation: contactFormGroup.get('occupation')?.value,
          phoneNumber: contactFormGroup.get('phoneNumber')?.value.e164Number,
          email: contactFormGroup.get('email')?.value,
          documentTypeId: documentTypeId,
          documentNumber: contactFormGroup.get('identifier')?.value,
          documentCountryIssuedId:
            identifierType !== 'arc'
              ? Number(contactFormGroup.get('identifierCountry')?.value)
              : 0,
          addressData: !Object.values(addressData).every(
            (value) => value === undefined
          )
            ? addressData
            : {},
        };
      }) || [];

    if (emergencyContactsObj.length !== 0) {
      patientObj.emergencyContacts = emergencyContactsObj;
    }

    const assignedDoctors = this.medicalComp.selectedDoctors.map((d) => {
      return {
        doctorId: d.id,
        primaryDoctor: d.primary,
      };
    });

    patientObj.assignedDoctors = assignedDoctors;

    patientObj.moh =
      this.medicalComp?.medicalForm.get('mohValue')?.value || false;

    patientObj.immobilityStatusId =
      this?.medicalComp?.medicalForm.get('immobilityValue')?.value || null;

    patientObj.bloodTypeId =
      this?.medicalComp?.medicalForm.get('bloodtype')?.value || null;
    patientObj.height =
      this?.medicalComp?.medicalForm.get('height')?.value || null;
    patientObj.weight =
      this?.medicalComp?.medicalForm.get('weight')?.value || null;

    patientObj.insurance = this?.insuranceComp?.insuranceForm
      .get('insurance')
      ?.value?.map((i: Number) => ({ id: i }));

    this.addPatient.savePatient(patientObj).subscribe({
      next: (response) => {
        console.log(response.data);
        this.uploadFileOnUser(response.data.id);

        this.isLoading = false;

        Swal.fire({
          text: 'Patient added successfully!',
          toast: true,
          position: 'bottom-end',
          showCancelButton: false,
          showConfirmButton: false,
          color: 'white',
          background: '#0d9488',
          timer: 3000,
        });
        // this.router.navigate(['/ehr/allPatients']);
        history.back();
      },
      error: (err) => {
        this.isLoading = false;

        let errorMessage = 'Unable to add patient!';

        if (err instanceof HttpErrorResponse && err.error?.message) {
          errorMessage += ` Error: ${err.error.message}`;
        }

        this.isLoading = false;
        Swal.fire({
          text: errorMessage,
          toast: true,
          position: 'bottom-end',
          showCancelButton: false,
          showConfirmButton: false,
          color: 'white',
          background: '#ff6969',
          timer: 3000,
        });
        console.log(errorMessage);
      },
    });
  }

  nextStep(stepper: MatStepper, stepIncrement: number): void {
    const currentStep = stepper.selectedIndex;
    const nextStep = currentStep + stepIncrement;

    // Get the form of the current step
    let currentStepForm: FormGroup | undefined;
    let formArray: FormArray | undefined;
    let isValidStep = true;

    switch (currentStep) {
      case 0:
        currentStepForm = this.identifiersComp?.identifiersForm;
        break;
      case 1:
        currentStepForm = this.personalInfoComp?.personalInfoForm;
        break;
      case 2:
        currentStepForm = this.medicalComp?.medicalForm;
        break;
      case 3:
        currentStepForm = this.addressComp?.addressForm;
        break;
      case 4:
        formArray = this.emergencyContacts?.emergencyContactsForm?.get(
          'contacts'
        ) as FormArray;
        break;
      case 5:
        currentStepForm = this.insuranceComp?.insuranceForm;
        break;
      default:
        break;
    }

    // Mark the form controls as touched
    if (currentStepForm) {
      Object.keys(currentStepForm.controls).forEach((controlName) => {
        const control = currentStepForm?.get(controlName);
        control?.markAsTouched();
      });
    } else if (formArray) {
      formArray.controls.forEach((abstractControl: AbstractControl) => {
        const contactForm = abstractControl as FormGroup;
        Object.keys(contactForm.controls).forEach((controlName) => {
          const control = contactForm.get(controlName);
          control?.markAsTouched();
        });
      });
    }

    // Specific validation for identifiers step
    if (currentStep === 0 && !this.isAtLeastOneIdentifierProvided()) {
      isValidStep = false;
      Swal.fire({
        text: this.translate.instant('At least one identifier is required!'),
        toast: true,
        position: 'bottom-end',
        showCancelButton: false,
        showConfirmButton: false,
        color: 'white',
        background: '#ff6969',
        timer: 3000,
      });
    }

    if (
      currentStep == 0 &&
      isValidStep &&
      this.identifiersComp.identifiersForm.valid
    ) {
      this.isLoading = true;
      const docs: IExistingDocument[] = [];

      if (
        this.identifiersComp?.identifiersForm.get('nationalId')?.value &&
        this.identifiersComp?.identifiersForm.get('nationalIdCountry')?.value
      ) {
        docs.push({
          documentTypeId: 1,
          documentNumber:
            this.identifiersComp?.identifiersForm.get('nationalId')?.value,
          documentCountryIssuedId:
            this.identifiersComp?.identifiersForm.get('nationalIdCountry')
              ?.value,
        });
      }

      if (
        this.identifiersComp?.identifiersForm.get('passport')?.value &&
        this.identifiersComp?.identifiersForm.get('passportCountry')?.value
      ) {
        docs.push({
          documentTypeId: 2,
          documentNumber:
            this.identifiersComp?.identifiersForm.get('passport')?.value,
          documentCountryIssuedId:
            this.identifiersComp?.identifiersForm.get('passportCountry')?.value,
        });
      }

      if (this.identifiersComp?.identifiersForm.get('arc')?.value) {
        docs.push({
          documentTypeId: 3,
          documentNumber:
            this.identifiersComp?.identifiersForm.get('arc')?.value,
        });
      }

      const existsObj: IExistingPatient = {
        email: this.identifiersComp?.identifiersForm.get('email')?.value || '',
        patientDocumentDto: docs,
      };
      this.patientService.patientExists(existsObj).subscribe({
        next: (response) => {
          isValidStep = true;

          this.isLoading = false;
          // Check if the form is valid before proceeding to the next step
          if (nextStep >= 0 && nextStep < stepper._steps.length) {
            if (isValidStep && (currentStepForm?.valid || formArray?.valid)) {
              stepper.selectedIndex = nextStep;
            } else {
              // Handle validation errors
              if (stepIncrement === -1 && stepper.selectedIndex != 0) {
                // Go back on second and after
                stepper.selectedIndex = stepper.selectedIndex + stepIncrement;
              } else {
                // Show error message on validation
                if (stepper.selectedIndex != 1) {
                } else {
                  // Identifiers Error
                }
              }
              console.log('Form contains validation errors.');
            }
          } else if (nextStep < 0 && currentStep > 0) {
            stepper.selectedIndex = nextStep;
          } else {
            // Handle validation errors or edge cases
            console.log('Form contains validation errors or invalid step.');
          }
        },
        error: (error) => {
          this.isLoading = false;
          isValidStep = false;

          Swal.fire({
            text: `${this.translate.instant(
              'This patient already exists. Do you want to navigate to the admission profile?'
            )}`,
            showCancelButton: true,
            cancelButtonText: `${this.translate.instant('Cancel')}`,
            showCloseButton: true,
            confirmButtonText: `${this.translate.instant('Admission Profile')}`,
          }).then((result) => {
            if (result.isConfirmed) {
              this.router.navigate([`/ehr/admission-profile`], {
                queryParams: {
                  patientId: error?.error?.data?.patientId,
                },
              });
            } else {
            }
          });

          console.log(error?.error?.data?.patientId);
        },
      });
    } else {
      if (nextStep >= 0 && nextStep < stepper._steps.length) {
        if (isValidStep && (currentStepForm?.valid || formArray?.valid)) {
          stepper.selectedIndex = nextStep;
        } else {
          if (stepIncrement === -1 && stepper.selectedIndex != 0) {
            stepper.selectedIndex = stepper.selectedIndex + stepIncrement;
          }
        }
      } else if (nextStep < 0 && currentStep > 0) {
        stepper.selectedIndex = nextStep;
      } else {
        // Handle validation errors or edge cases
        console.log('Form contains validation errors or invalid step.');
      }
    }
  }

  isAtLeastOneIdentifierProvided(): boolean {
    const identifiersForm = this.identifiersComp?.identifiersForm?.value;

    return (
      (identifiersForm?.nationalId && identifiersForm?.nationalIdCountry) ||
      (identifiersForm?.passport && identifiersForm?.passportCountry) ||
      identifiersForm?.arc
    );
  }

  uploadProfilePicture(patientId: number, profilePicture: File) {
    const formData = new FormData();
    formData.append('PatientId', patientId.toString());
    formData.append('PatientPicture', profilePicture);

    this.addPatient.uploadProfilePicture(formData).subscribe({
      next: () => {
        Swal.fire({
          text: 'Patient profile picture added successfully!',
          toast: true,
          position: 'bottom-end',
          showCancelButton: false,
          showConfirmButton: false,
          color: 'white',
          background: '#0d9488',
          timer: 3000,
        });
      },
      error: (err) => {
        let errorMessage = 'Unable to add patient profile picture!';

        //is an HttpErrorResponse and contains the error message
        if (err instanceof HttpErrorResponse && err.error?.message) {
          errorMessage += ` Error: ${err.error.message}`;
        }
        Swal.fire({
          text: errorMessage,
          toast: true,
          position: 'bottom-end',
          showCancelButton: false,
          showConfirmButton: false,
          color: 'white',
          background: '#ff6969',
          timer: 3000,
        });
        console.log(errorMessage);
      },
    });
  }

  async uploadFileOnUser(patientId: number) {
    try {
      const pictureToUpload = this.addPatient.getProfilePicture();
      if (pictureToUpload != null) {
        const extension = this.getProfilePictureFileExtension();
        const file = new File([pictureToUpload], `ProfilePicture.${extension}`);
        this.uploadProfilePicture(patientId, file);
        this.addPatient.setProfilePicture(null);
        console.log('Profile picture uploaded successfully');
      }
    } catch (error) {
      console.error('Failed to upload profile picture:', error);
    }
  }

  getProfilePictureFileExtension(): string | null {
    const profilePictureBlob = this.addPatient.getProfilePicture();
    if (profilePictureBlob != null) {
      if (profilePictureBlob instanceof Blob) {
        const mimeType = profilePictureBlob.type;
        return mimeType.split('/')[1];
      }
    }
    return null;
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
