import { Component } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import Swal from 'sweetalert2';
import { IVaccinationDetailed } from '../../../../../../interfaces/Vaccination/IVaccinationDetailed';
import { ITravelHistoryDetailed } from '../../../../../../interfaces/TravelHistory/ITravelHistoryDetailed';
import { TravelHistoryService } from '../../../../../../services/travelHistory.service';
import { TravelHistoryAddEditComponent } from './travel-history-add-edit/travel-history-add-edit.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-travel-history',
  templateUrl: './travel-history.component.html',
  styleUrls: ['./travel-history.component.css'],
})
export class TravelHistoryComponent {
  public addComp = TravelHistoryAddEditComponent;

  private destroy$ = new Subject<void>();

  travelHistory?: ITravelHistoryDetailed[];
  isLoading: boolean = false;

  constructor(
    private readonly travelHistoryService: TravelHistoryService,
    private readonly translate: TranslateService
  ) {}

  ngOnInit() {
    this.travelHistoryService.travelHistoryLoadingSub
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: boolean) => {
        this.isLoading = data;
      });

    this.travelHistoryService.travelHistoryDataSub
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: IVaccinationDetailed[]) => {
        this.travelHistory = data;
      });

    this.travelHistoryService.getData();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  openModalFun(item?: ITravelHistoryDetailed) {
    this.travelHistoryService.travelHistoryModalSub.next(true);
    this.travelHistoryService.travelHistoryEditSub.next(item);
  }

  deleteFun(travelHistoryId: number) {
    Swal.fire({
      text: `${this.translate.instant(
        'deletePlaceholder1'
      )} ${this.translate.instant('Travel History')}?`,
      showDenyButton: false,
      showCancelButton: true,
      cancelButtonText: `${this.translate.instant('Cancel')}`,
      confirmButtonText: `${this.translate.instant('Ok')}`,
    }).then((result) => {
      if (result.isConfirmed) {
        this.travelHistoryService
          .deleteTravelHistory(travelHistoryId.toString())
          .subscribe({
            next: (d) => {
              Swal.fire({
                text: 'Travel history deleted successfully!',
                toast: true,
                position: 'bottom-end',
                showCancelButton: false,
                showConfirmButton: false,
                color: 'white',
                background: '#0d9488',
                timer: 3000,
              });

              this.travelHistoryService.deleteTravelHistoryFromTable(
                travelHistoryId.toString()
              );
            },
            error: (err) => {
              Swal.fire({
                text: 'Unable to delete travel history!',
                toast: true,
                position: 'bottom-end',
                showCancelButton: false,
                showConfirmButton: false,
                color: 'white',
                background: '#ff6969',
                timer: 3000,
              });
              console.log(err);
            },
          });
      } else if (result.isDenied) {
        Swal.fire('Travel history is not deleted', '', 'info');
      }
    });
  }
}
