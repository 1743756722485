import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subscription, filter } from 'rxjs';
import { loadMenuFailure, loadMenuSuccess } from 'src/app/actions/menu.actions';
import { IModule } from 'src/app/interfaces/IModule';
import { selectMenu } from 'src/app/selectors/menu.selector';
import { HysteroscopyFileService } from 'src/app/services/hyseroscopyFile.service';
import { MenuService } from 'src/app/services/menu.service';
import { PatientService } from 'src/app/services/patient.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-ehr',
  templateUrl: './ehr.component.html',
  styleUrls: ['./ehr.component.css'],
})
export class EhrComponent {
  // All Patients
  // Defaukt active Menu item
  private defaultTab: number = 0;

  public menuList: IModule[] = [];
  public activeButton: string = '';
  private subscription: Subscription;
  private subscriptions: Subscription[] = [];

  ongoingHysteroscopyExamination: boolean = false;

  constructor(
    private readonly menuService: MenuService,
    private readonly patientService: PatientService,
    private readonly router: Router,
    private readonly store: Store,
    private readonly hysteroscopyFileService: HysteroscopyFileService
  ) {
    this.subscription = this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((url: any) => {
        if (url?.url) {
          const segments = url?.url?.split('/');
          const lastSegment = segments[segments.length - 1];

          this.activeButton = lastSegment;
        }
      });
    this.subscriptions.push(
      this.hysteroscopyFileService
        .getExaminationStatus()
        .subscribe((status: boolean) => {
          if (status) {
            this.ongoingHysteroscopyExamination = status;
          } else {
            this.ongoingHysteroscopyExamination = false;
          }
        })
    );
  }

  ngOnInit() {
    this.menuService.getMenu().subscribe({
      next: (d) => {
        this.menuList = d?.data.sort(
          (a: IModule, b: IModule) => a.orderBy - b.orderBy
        );

        this.store.dispatch(loadMenuSuccess({ menu: this.menuList }));
        if (this.router.url === '/ehr') {
          this.router.navigate([
            '/ehr/' + this.menuList[this.defaultTab]?.path,
          ]);
        }
      },
      error: (e) => {
        console.log(e);
        this.store.dispatch(loadMenuFailure({ error: e }));
      },
    });

    this.patientService.getDefinitions().subscribe({
      next: (response) => {
        this.patientService.definitionsSignal.set(
          response?.data?.allAreaDefinitions!
        );
      },
      error: (err) => {
        Swal.fire({
          text: 'Unable to retrieve Definitions!',
          toast: true,
          position: 'bottom-end',
          showCancelButton: false,
          showConfirmButton: false,
          color: 'white',
          background: '#ff6969',
          timer: 3000,
        });
        console.error('Error fetching patient information:', err);
      },
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
