import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { KeycloakService } from 'keycloak-angular';
import { ILogin } from 'src/app/interfaces/Keycloak/ILogin';
import { OurKeycloakService } from 'src/app/services/keycloak.service';

@Component({
  selector: 'app-newlogin',
  templateUrl: './newlogin.component.html',
  styleUrl: './newlogin.component.css',
})
export class NewloginComponent {
  username: string = '';
  password: string = '';

  passwordFieldType: string = 'password';

  togglePasswordVisibility(): void {
    this.passwordFieldType =
      this.passwordFieldType === 'password' ? 'text' : 'password';
  }

  constructor(
    private readonly ourKeycloakService: OurKeycloakService,
    private readonly keycloak: KeycloakService,
    private readonly router: Router
  ) {}

  login() {
    const loginObj: ILogin = {
      username: 'adminamen',
      password: 'adminamen',
    };

    this.ourKeycloakService.login(loginObj).subscribe({
      next: (res) => {
        const ki = this.keycloak.getKeycloakInstance();
      },
      error: (err) => {
        console.log(err);
      },
    });
  }

  async ngOnInit() {
    if (await this.keycloak.isLoggedIn()) {
      this.router.navigate(['/ehr']);
    }
  }
}
