<nz-modal #modalRef nzSize [(nzVisible)]="isVisible"
    [nzTitle]="((isEdit ? 'Edit' : 'Add') + ' Pregnancy Status') | translate" (nzOnCancel)="handleCancel()"
    nzCancelText="Cancel" nzOkText="Save" nzOkType="primary" [nzFooter]="null" class="custom-modal-class">
    <ng-container *nzModalContent>
        <form [formGroup]="pregnancyStatusForm" (ngSubmit)="submitForm()"
            [ngClass]="{'status-submitted': pregnancyStatusSubmitted, 'status-not-submitted': !pregnancyStatusSubmitted}">
            <div class="form-row" fxLayout="row" fxLayoutAlign="start center">
                <div fxLayout="col" fxLayoutAlign="start center" fxFlex="30">
                    <label>{{"Date of Observation" | translate}}</label>
                </div>
                <div fxLayout="col" fxLayoutAlign="start center" fxFlex="70">
                    <input nz-input class="inputs status" type="date" [placeholder]="'Onset Date' | translate"
                        formControlName="dateObservationValue"
                        [min]="this.pregnancyStatusForm.value?.pregnancyEstimatedValue" [max]="getCurrentDate()" />
                </div>
            </div>


            <div class="form-row" fxLayout="row" fxLayoutAlign="start center">
                <div fxLayout="col" fxLayoutAlign="start center" fxFlex="30">
                    <label>{{"Status" | translate}}</label>
                </div>
                <div fxLayout="col" fxLayoutAlign="start center" fxFlex="70">
                    <nz-select class="inputs status" nzShowSearch nzAllowClear [nzPlaceHolder]="'Status' | translate"
                        formControlName="statusValue" [nzLoading]="isLoading">
                        <ng-container *ngFor="let et of statusDropdown">
                            <nz-option [nzLabel]="et.name" [nzValue]="et.id"></nz-option>
                        </ng-container>
                    </nz-select>
                </div>
            </div>


            <div class="form-row" fxLayout="row" fxLayoutAlign="start center">
                <div fxLayout="col" fxLayoutAlign="start center" fxFlex="30">
                    <label>{{"Pregnancy Estimation Type" | translate}}</label>
                </div>
                <div fxLayout="col" fxLayoutAlign="start center" fxFlex="70">
                    <nz-select class="inputs status" nzShowSearch nzAllowClear [nzPlaceHolder]="'Pregnancy Estimation Type ' | translate"
                        formControlName="pregnancyEstimatedValue" [nzLoading]="isLoading">
                        <ng-container *ngFor="let et of pregnancyEstimatedDropdown">
                            <nz-option [nzLabel]="et.name" [nzValue]="et.id"></nz-option>
                        </ng-container>
                    </nz-select>
                </div>
            </div>

            <div class="form-row" fxLayout="row" fxLayoutAlign="start center">
                <div fxLayout="col" fxLayoutAlign="start center" fxFlex="30">
                    <label>{{"Pregnacy Estimation Date" | translate}}</label>
                </div>
                <div fxLayout="col" fxLayoutAlign="start center" fxFlex="70">
                    <input nz-input class="inputs status" type="date" [placeholder]="'Pregnacy Estimation Date ' | translate"
                        formControlName="estimatedDateValue"
                        [min]="getCurrentDate() || this.pregnancyStatusForm.value?.dateObservationValue"
                        [max]="getOneYear()" />
                </div>
            </div>

            <div fxLayout="row" fxLayoutAlign="end" class="footer">
                <button nz-button nzPrimary class="save-btn" type="submit" [nzLoading]="isLoading"
                    [disabled]="isLoading">{{"Save" | translate}}</button>
                <button nz-button nzDanger class="cancel-btn" type="button" (click)="handleCancel()"
                    [disabled]="isLoading">{{"Cancel" | translate}}</button>
            </div>
        </form>
    </ng-container>
</nz-modal>