import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { IQuestionnaireQuestion } from 'src/app/interfaces/Questionnaire/IQuestionnaire';
import { EpisodeOfCareService } from 'src/app/services/episodeOfCare.service';

@Component({
  selector: 'app-dynamic-questions',
  templateUrl: './dynamic-questions.component.html',
  styleUrls: ['./dynamic-questions.component.css'],
})
export class DynamicQuestionsComponent implements OnInit {
  @Input() elements: IQuestionnaireQuestion[] = [];
  @Input() formGroup!: FormGroup;

  constructor(
    private fb: FormBuilder,
    private readonly eocService: EpisodeOfCareService,
    private readonly router: Router,
    private readonly cdr: ChangeDetectorRef
  ) {}

  isInpatientView(): boolean {
    return (
      !(
        this.eocService.currentEocId() === null ||
        this.eocService.currentEocId() === ''
      ) && this.router.url.includes('inPatientView')
    );
  }

  isEOC() {
    return (
      !(
        this.eocService.currentEocId() === null ||
        this.eocService.currentEocId() === ''
      ) && this.router.url.includes('episodeOfCare')
    );
  }

  ngOnInit() {
    // Initialize form controls for each element with the correct disabled state
    this.elements.forEach((element) => {
      this.formGroup.addControl(
        element.name,
        this.fb.control({
          value: '',
          disabled: !this.isInpatientView() && !this.isEOC(),
        })
      );
    });
    console.log(this.elements);
    this.cdr.detectChanges();
  }
}
