import { AfterViewInit, Component, Input } from '@angular/core';
import { Chart } from 'chart.js/auto';
import { InpatientService } from '../../../../services/inpatient.service';
import { IDashboardChart } from '../../../../interfaces/Dashboard/IDashboardChart';
import { TranslateService } from '@ngx-translate/core';
import { IDashboardData } from 'src/app/interfaces/Dashboard/IDashboardData';

@Component({
  selector: 'app-dashboard-inpatient-by-gender',
  templateUrl: './dashboard-inpatient-by-gender.component.html',
  styleUrls: ['./dashboard-inpatient-by-gender.component.css'],
})
export class DashboardInpatientByGenderComponent implements AfterViewInit {
  @Input() isAMEN?: boolean;
  constructor(
    private readonly inPatientService: InpatientService,
    private translate: TranslateService
  ) {}
  ngAfterViewInit(): void {
    this.getData();
  }

  public InPatientGender: IDashboardChart | null = null;

  BarChart(): void {
    const datasets = this.InPatientGender?.dataSet || [];
    const maxYAxisValue = this.setBuffer(this.InPatientGender?.dataSet!);

    const data = {
      labels: [''],
      datasets: datasets.map((dataset, index) => ({
        label: this.translate.instant(dataset.label || ''),
        data: dataset.data,
        backgroundColor: [dataset.backgroundColor],
        borderColor: 'rgba(128, 128, 128, 0.6)',
        borderRadius: 10,
        borderWidth: 1,
      })),
      // datasets: [
      //   {
      //     label: 'In-Patient Gender', // this must be from db
      //     data: dataSet,
      //     backgroundColor: 'rgba(75, 192, 192, 0.2)',
      //     borderColor: 'rgba(75, 192, 192, 1)',
      //     borderRadius: 10, // Set the border radius to make the chart rounded
      //     borderWidth: 1
      //   }
    };

    new Chart('inPatientGender', {
      type: 'bar',
      data: data,
      options: {
        maintainAspectRatio: false,
        scales: {
          y: {
            beginAtZero: true,
            max: maxYAxisValue,
            ticks: {
              stepSize: 1,
            },
          },
        },
        plugins: {
          title: {
            display: true,
            text: this.translate.instant('Inpatients by Gender'),
          },
        },
      },
    });
  }

  setBuffer(data: IDashboardData[]): number {
    const numericData =
      data.flatMap(
        (dataSet) => dataSet.data?.map((str) => parseFloat(str) || 0) || [0]
      ) || 0;

    const maxDataValue = Math.max(...numericData);

    const buffer = 3;

    const maxYAxisValue = maxDataValue + buffer;

    return maxYAxisValue;
  }

  getData() {
    this.inPatientService.getDashboardInPatientByGender().subscribe({
      next: (response) => {
        this.InPatientGender = response?.data;
        this.BarChart();
      },
      error: (err) => {
        console.log(err);
      },
    });
  }
}
