<div class="add-patient-body">
  <form [formGroup]="patientForm" (ngSubmit)="savePatientForm()">

    <mat-vertical-stepper #stepper class="stepper">
      <mat-step [label]="'Patient\'s Identifiers' | translate">
        <app-identifiers [countriesList]="countriesList" formGroupName="identifiers" [formSubmitted]="formSubmitted" />
        <div fxLayout="row" fxLayoutAlign="end center">
          <button nz-button class="add-patient-button" (click)="nextStep(stepper, 1)" type="button"
            [nzLoading]="isLoading">
            {{ "Next" | translate }}
          </button>
        </div>
      </mat-step>

      <mat-step [label]="'Patient\'s Personal Information' | translate">
        <app-personal-info [gendersList]="gendersList" [placeOfBirthList]="placeOfBirthList"
          [closestRelativesList]="closestRelativesList" [educationLevelList]="educationLevelList"
          [familyStatusList]="familyStatusList" [sourceOfIncomeList]="sourceOfIncomeList" [religionList]="religionList"
          formGroupName="personalInfo" [formSubmitted]="formSubmitted" />
        <div fxLayout="row" fxLayoutAlign="end center">
          <button class="back-button" (click)="nextStep(stepper, -1)" type="button">
            Back
          </button>
          <button class="add-patient-button" (click)="nextStep(stepper, 1)" type="button">
            {{ "Next" | translate }}
          </button>
        </div>
      </mat-step>

      <mat-step [label]="'Patient\'s Medical Information' | translate">
        <app-medical-info formGroupName="medical" [formSubmitted]="formSubmitted" [bloodTypesList]="bloodTypesList"
          [immobilityList]="immobilityList" [doctors]="doctors" />
        <div fxLayout="row" fxLayoutAlign="center center" class="add-patient-row">
          <div fxLayout="col" fxFlex="100" class="add-patient-col" style="justify-content: end">
            <button class="back-button" (click)="nextStep(stepper, -1)" type="button">
              Back
            </button>
            <button class="add-patient-button" (click)="nextStep(stepper, 1)" type="button">
              {{ "Next" | translate }}
            </button>
          </div>
        </div>
      </mat-step>

      <mat-step [label]="'Patient\'s Address' | translate">
        <app-address [countriesList]="countriesList" formGroupName="address" [formSubmitted]="formSubmitted" />
        <div fxLayout="row" fxLayoutAlign="end center">
          <button class="back-button" (click)="nextStep(stepper, -1)" type="button">
            Back
          </button>
          <button class="add-patient-button" (click)="nextStep(stepper, 1)" type="button">
            {{ "Next" | translate }}
          </button>
        </div>
      </mat-step>

      <mat-step [label]="'Patient\'s Emergency Contacts' | translate">
        <app-emergency-contacts [countriesList]="countriesList" [closestRelativesList]="closestRelativesList"
          formGroupName="emergencyContacts" [formSubmitted]="formSubmitted" />
        <div fxLayout="row" fxLayoutAlign="end center">
          <button class="back-button" (click)="nextStep(stepper, -1)" type="button">
            Back
          </button>
          <button class="add-patient-button" (click)="nextStep(stepper, 1)" type="button">
            {{ "Next" | translate }}
          </button>
        </div>
      </mat-step>

      <mat-step [label]="'Patient\'s Insurance' | translate">
        <app-insurance formGroupName="insurance" [formSubmitted]="formSubmitted" [insuranceList]="insuranceList" />
        <div fxLayout="row" fxLayoutAlign="center center" class="add-patient-row">
          <div fxLayout="col" fxFlex="100" class="add-patient-col" style="justify-content: end">
            <button class="back-button" (click)="nextStep(stepper, -1)" type="button">
              {{ "Back" | translate }}
            </button>
            <button nz-button nzPrimary class="add-patient-button" type="submit" [nzLoading]="isLoading"
              [disabled]="isLoading">
              {{ "Add Patient" | translate }}
            </button>
          </div>
        </div>
      </mat-step>
    </mat-vertical-stepper>
  </form>
</div>