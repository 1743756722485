import { CommonModule, NgTemplateOutlet } from '@angular/common';
import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzTableModule } from 'ng-zorro-antd/table';
import { EpisodeOfCareService } from 'src/app/services/episodeOfCare.service';
import { FlexModule } from '@angular/flex-layout';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { MatIconModule } from '@angular/material/icon';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { FormsModule } from '@angular/forms';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzPaginationModule } from 'ng-zorro-antd/pagination';
import { TooltipComponent } from 'src/app/common/tooltip/tooltip.component';
import { ILaboratoryFileDetailed } from 'src/app/interfaces/LaboratoryFile/ILaboratoryFileDetailed';
import { LaboratoryFileService } from 'src/app/services/laboratoryFile.service';
import { Subject, takeUntil } from 'rxjs';
import { BrowserModule } from '@angular/platform-browser';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import Swal from 'sweetalert2';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { LaboratoryFileAddComponent } from './laboratory-file-add/laboratory-file-add.component';
import moment from 'moment';

@Component({
  standalone: true,
  selector: 'app-laboratory-file',
  templateUrl: './laboratory-file.component.html',
  styleUrls: ['./laboratory-file.component.css'],
  imports: [
    NzLayoutModule,
    NzTableModule,
    TranslateModule,
    CommonModule,
    FlexModule,
    NzToolTipModule,
    NzIconModule,
    MatIconModule,
    TooltipComponent,
    NzSwitchModule,
    FormsModule,
    NzButtonModule,
    NzModalModule,
    NzPaginationModule,
    NgTemplateOutlet,
    BrowserModule,
    PdfViewerModule,
    NzSpinModule,
  ],
})
export class LaboratoryFileComponent {
  @Input() isPreview: boolean = false;
  @Input() isLoading?: boolean = false;
  isPreviewLoading?: boolean = false;

  @Input() pagination?: boolean = true;

  public addComp = LaboratoryFileAddComponent;

  private destroy$ = new Subject<void>();

  pdfObj?: string;
  pdfItem?: ILaboratoryFileDetailed;

  @Input() data?: ILaboratoryFileDetailed[];

  currentPageIndex: number = 1;
  pageSize: number = 5;

  constructor(
    private readonly laboratoryService: LaboratoryFileService,
    private readonly eocService: EpisodeOfCareService,
    private readonly router: Router,
    private readonly translate: TranslateService
  ) {}

  ngOnInit() {
    this.laboratoryService.laboratoryLoadingSub
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: boolean) => {
        this.isLoading = data;
      });

    if (!this.data) {
      this.laboratoryService.laboratoryDataSub
        .pipe(takeUntil(this.destroy$))
        .subscribe((data: ILaboratoryFileDetailed[]) => {
          this.data = data;
        });

      this.laboratoryService.getData();
    }
  }

  isEOC() {
    return (
      !(
        this.eocService.currentEocId() === null ||
        this.eocService.currentEocId() === ''
      ) && this.router.url.includes('episodeOfCare')
    );
  }

  isInpatientView() {
    return (
      !(
        this.eocService.currentEocId() === null ||
        this.eocService.currentEocId() === ''
      ) && this.router.url.includes('inPatientView')
    );
  }

  hasUnsubmitted() {
    return this.getData()?.find(
      (d) => d?.['isSubmitted'] !== undefined && !d?.['isSubmitted']
    );
  }

  getData() {
    if (this.pagination) {
      const startIndex = (this.currentPageIndex - 1) * this.pageSize;
      const endIndex = startIndex + this.pageSize;
      return this?.data?.slice(startIndex, endIndex) || [];
    }
    return this?.data;
  }

  handlePageIndexChange(pageIndex: number): void {
    this.currentPageIndex = pageIndex;
  }

  handlePageSizeChange(pageSize: number): void {
    this.pageSize = pageSize;
    this.currentPageIndex = 1; // Reset to the first page when changing page size
  }

  openModal(item?: ILaboratoryFileDetailed) {
    this.laboratoryService.laboratoryModalSub.next(true);
    this.laboratoryService.laboratoryEditSub.next(item);
  }

  deleteFun(id: number) {
    Swal.fire({
      text: `${this.translate.instant(
        'deletePlaceholder3'
      )} ${this.translate.instant('Laboratory')}?`,
      showDenyButton: false,
      showCancelButton: true,
      cancelButtonText: `${this.translate.instant('Cancel')}`,
      confirmButtonText: `${this.translate.instant('Ok')}`,
    }).then((result) => {
      if (result.isConfirmed) {
        this.laboratoryService.deleteLaboratory(id.toString()).subscribe({
          next: (d) => {
            Swal.fire({
              text: 'Laboratory deleted successfully!',
              toast: true,
              position: 'bottom-end',
              showCancelButton: false,
              showConfirmButton: false,
              color: 'white',
              background: '#0d9488',
              timer: 3000,
            });

            if (id == this?.pdfItem?.id) {
              this.pdfObj = undefined;
              this.pdfItem = undefined;
            }

            this.laboratoryService.deleteLaboratoryFromTable(id.toString());
          },
          error: (err) => {
            Swal.fire({
              text: 'Unable to delete Laboratory!',
              toast: true,
              position: 'bottom-end',
              showCancelButton: false,
              showConfirmButton: false,
              color: 'white',
              background: '#ff6969',
              timer: 3000,
            });
            console.log(err);
          },
        });
      } else if (result.isDenied) {
        Swal.fire('Laboratory is not deleted', '', 'info');
      }
    });
  }

  requestPDF(item?: ILaboratoryFileDetailed) {
    this.isPreviewLoading = true;
    this.laboratoryService.getPDF(item?.id?.toString()!).subscribe({
      next: (res) => {
        this.pdfObj = URL.createObjectURL(res);
        this.pdfItem = item;
        this.isPreviewLoading = false;
      },
      error: (err) => {
        Swal.fire({
          text: 'Unable to retrieve the document!',
          toast: true,
          position: 'bottom-end',
          showCancelButton: false,
          showConfirmButton: false,
          color: 'white',
          background: '#ff6969',
          timer: 3000,
        });
        this.pdfObj = undefined;
        this.pdfItem = undefined;

        this.isPreviewLoading = false;
        console.log(err);
      },
    });
  }

  downloadPDF(item: ILaboratoryFileDetailed) {
    this.laboratoryService.downloadPDF(item);
  }

  getValue(field: string, value?: string) {
    if (!value) {
      return '-';
    }

    if (field?.toLowerCase()?.includes('date time')) {
      console.log(value);
      return moment(value).format('YYYY-MM-DD h:mm:ss a');
    } else if (field?.toLowerCase()?.includes('date')) {
      return moment(value).format('YYYY-MM-DD');
    } else {
      return value;
    }
  }
}
