import { ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { KeycloakService } from 'keycloak-angular';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { NzSelectOptionInterface } from 'ng-zorro-antd/select';
import { IAdmissionDropdown } from 'src/app/interfaces/AdmissionPatient/IAdmissionDropdown';
import { IAdmissionPatientDetailed } from 'src/app/interfaces/AdmissionPatient/IAdmissionPatientDetailed';
import { ISearchAdmission } from 'src/app/interfaces/AdmissionPatient/ISearchAdmission';
import { AdmissionService } from 'src/app/services/admission.service';
import { AllergyService } from 'src/app/services/allergy.service';
import { CarePlanService } from 'src/app/services/carePlan.service';
import { CurrentMedicationService } from 'src/app/services/currentMedication.service';
import { CurrentProblemsService } from 'src/app/services/currentProblems.service';
import { DischargeService } from 'src/app/services/discharge.service';
import { EpisodeOfCareService } from 'src/app/services/episodeOfCare.service';
import { HysteroscopyFileService } from 'src/app/services/hyseroscopyFile.service';
import { ImagingService } from 'src/app/services/imaging.service';
import { LaboratoryFileService } from 'src/app/services/laboratoryFile.service';
import { MedicalAlertService } from 'src/app/services/medicalAlert.service';
import { MedicalDeviceService } from 'src/app/services/medicalDevice.service';
import { MedicalHistoryService } from 'src/app/services/medicalHistory.service';
import { PastMedicationService } from 'src/app/services/pastMedication.service';
import { PatientService } from 'src/app/services/patient.service';
import { PregnancyHistoryService } from 'src/app/services/pregnancyHistory.service';
import { PregnancyOutcomeService } from 'src/app/services/pregnancyOutcome.service';
import { PregnancyStatusService } from 'src/app/services/pregnancyStatus.service';
import { ProcedureService } from 'src/app/services/procedure.service';
import { ResolvedProblemsService } from 'src/app/services/resolvedProblems.service';
import { SocialHistoryService } from 'src/app/services/socialHistory.service';
import { TravelHistoryService } from 'src/app/services/travelHistory.service';
import { VaccinationService } from 'src/app/services/vaccination.service';
import { VitalSignService } from 'src/app/services/vitalSign.service';
import Swal from 'sweetalert2';
import { AdmissionRejectModalComponent } from './admission-reject-modal/admission-reject-modal.component';
import { AdmissionAdmitModalComponent } from './admission-admit-modal/admission-admit-modal.component';
import { en_US, NzI18nService } from 'ng-zorro-antd/i18n';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-admission',
  templateUrl: './admission.component.html',
  styleUrl: './admission.component.css',
})
export class AdmissionComponent {
  @ViewChild('inpaginator', { static: true }) paginator!: MatPaginator;

  searchForm: FormGroup;

  isLoading = false;

  openModalID: number = -1;

  public availablePatients: NzSelectOptionInterface[] = [];

  public data: MatTableDataSource<IAdmissionPatientDetailed> =
    new MatTableDataSource<IAdmissionPatientDetailed>([]);

  public totalRecords: number = 0;

  dropdownData: IAdmissionDropdown = {};

  public displayedColumns: string[] = [
    'documentNumber',
    'firstName',
    'lastName',
    'dateOfBirth',
    'age',
    'dateOfApplication',
    'registrationStatus',
    'fileRatio',
    'admission-actions',
  ];

  ngAfterViewInit() {
    this.data.paginator = this.paginator;
  }

  constructor(
    private readonly modal: NzModalService,
    private readonly admissionService: AdmissionService,
    private readonly keycloakService: KeycloakService,
    private readonly eocService: EpisodeOfCareService,
    private readonly patientService: PatientService,
    private readonly router: Router,
    private readonly fb: FormBuilder,
    private readonly allergyService: AllergyService,
    private readonly vaccinationService: VaccinationService,
    private readonly medicalAlertService: MedicalAlertService,
    private readonly medicalHistoryService: MedicalHistoryService,
    private readonly procedureService: ProcedureService,
    private readonly medicalDeviceService: MedicalDeviceService,
    private readonly currentProblemsService: CurrentProblemsService,
    private readonly resolvedProblemsService: ResolvedProblemsService,
    private readonly pregnancyHistoryService: PregnancyHistoryService,
    private readonly pregnancyOutcomeService: PregnancyOutcomeService,
    private readonly pregnancyStatusService: PregnancyStatusService,
    private readonly carePlanService: CarePlanService,
    private readonly socialHistoryService: SocialHistoryService,
    private readonly travelHistoryService: TravelHistoryService,
    private readonly currentMedicationService: CurrentMedicationService,
    private readonly pastMedicationService: PastMedicationService,
    private readonly imagingService: ImagingService,
    private readonly labService: LaboratoryFileService,
    private readonly hysteroscopyFileService: HysteroscopyFileService,
    private readonly vitalSignService: VitalSignService,
    private readonly dischargeService: DischargeService,
    private cdr: ChangeDetectorRef,
    private readonly nz: NzI18nService,
    private readonly translate: TranslateService
  ) {
    // TODO - Temporary fix
    // NG Zorro Datepicker doesnt have a greek locale
    // dashboard.component.html:29 [NG-ZORRO]: Missing translations for "DatePicker.lang.rangeWeekPlaceholder" in language "el".
    // You can use "NzI18nService.setLocale" as a temporary fix.
    // Welcome to submit a pull request to help us optimize the translations!
    //
    // If you change locale to greek manually, it will break
    this.nz.setLocale(en_US);

    this.searchForm = this.fb.group({
      registrationValue: [''], // Initialize with an array of values
      mohValue: [false],
      chronicPatientValue: [''],
      dateValue: [''],
      documentValue: [''],
      selectAll: [''],
    });
  }

  onSelectAllChange(isChecked: boolean) {
    if (isChecked) {
      const allIds = this.dropdownData?.registrationStatus?.map(
        (item: any) => item.id
      );
      this.searchForm.get('registrationValue')?.setValue(allIds);
    } else {
      this.searchForm.get('registrationValue')?.setValue([4]); // 4 == id of pending
    }

    this.dropdownChanged();
  }

  dropdownChanged() {
    this.paginator.pageIndex = 0;
    this.searchPatientsForm();
  }

  setSearchObj() {
    const searchParams: ISearchAdmission = {};
    if (this.searchForm.get('registrationValue')?.value?.length > 0) {
      searchParams.registrationStatusId =
        this.searchForm.get('registrationValue')?.value;
    } else {
      const allIds = this.dropdownData?.registrationStatus?.map(
        (item: any) => item.id
      );
      searchParams.registrationStatusId = allIds;
      // this.searchForm.get('selectAll')?.setValue(true);
    }

    if (this.searchForm.get('mohValue')?.value == true) {
      searchParams.moh = this.searchForm.get('mohValue')?.value;
    }

    if (this.searchForm.get('chronicPatientValue')?.value == true) {
      searchParams.moh = this.searchForm.get('chronicPatientValue')?.value;
    }

    // if mohValue is false or undefined remove
    // moh field from search parameters
    if (!this.searchForm.get('mohValue')?.value) {
      delete searchParams.moh;
    }

    if (!this.searchForm.get('chronicPatientValue')?.value) {
      delete searchParams.chronicPatient;
    }

    if (
      this.searchForm.get('dateValue')?.value?.at(0) &&
      this.searchForm.get('dateValue')?.value?.at(1)
    ) {
      searchParams.applicationStartDate = this.searchForm.get('dateValue')
        ?.value[0]
        ? new Date(this.searchForm.get('dateValue')?.value[0])
            .toISOString()
            .split('T')[0]
        : undefined;
      searchParams.applicationEndDate = this.searchForm.get('dateValue')
        ?.value[1]
        ? new Date(this.searchForm.get('dateValue')?.value[1])
            .toISOString()
            .split('T')[0]
        : undefined;
    }

    if (this.searchForm.get('documentValue')?.value) {
      searchParams.documentNumber = this.searchForm.get('documentValue')?.value;
    }
    return searchParams;
  }

  async searchPatientsForm() {
    this.openModalID = -1;
    await this.admissionService
      .getAdmissionPatients(
        this.setSearchObj(),
        this.paginator.pageSize,
        this.paginator.pageIndex + 1
      )
      .subscribe({
        next: (d) => {
          this.data = new MatTableDataSource(
            d?.data?.map((d: IAdmissionPatientDetailed) => d)
          );

          this.totalRecords = d.totalRecords || 0;
        },
        error: (err) => {},
      });
  }

  getDropdownData() {
    this.admissionService.getDropdownData().subscribe({
      next: (d) => {
        // Default value - pending
        // this.searchForm.get('registrationValue')?.setValue([1, 2, 3, 4]);
        // this.searchForm.get('selectAll')?.setValue(true);
        this.dropdownData = d.data;
        this.dropdownData.registrationStatus =
          this.dropdownData.registrationStatus?.map((i) => ({
            ...i,
            name: this.translate.instant(i.name),
          }));

        this.dropdownChanged();
      },
      error: (err) => {},
    });
  }

  ngOnInit() {
    this.searchForm.patchValue({
      registrationValue: [2, 3, 4, 5],
      selectAll: [true],
    });

    this.getDropdownData();
  }

  clearDataFromServices() {
    this.allergyService.allergyDataSub.next({});
    this.vaccinationService.vaccinationDataSub.next([]);
    this.medicalAlertService.medicalAlertDataSub.next([]);
    this.medicalHistoryService.medicalHistoryDataSub.next([]);
    this.procedureService.procedureDataSub.next({});
    this.medicalDeviceService.medicalDeviceDataSub.next({});
    this.currentProblemsService.currentProblemDataSub.next({});
    this.resolvedProblemsService.resolvedProblemDataSub.next([]);
    this.pregnancyHistoryService.pregnancyHistoryDataSub.next([]);
    this.pregnancyOutcomeService.pregnancyOutcomeDataSub.next([]);
    this.pregnancyStatusService.pregnancyStatusDataSub.next([]);
    this.carePlanService.carePlanDataSub.next([]);
    this.socialHistoryService.socialHistoryDataSub.next([]);
    this.travelHistoryService.travelHistoryDataSub.next([]);
    this.currentMedicationService.currentMedicationDataSub.next({});
    this.pastMedicationService.pastMedicationDataSub.next([]);
    this.imagingService.imagingDataSub.next([]);
    this.labService.laboratoryDataSub.next([]);
    this.hysteroscopyFileService.hysteroscopyDataSub.next([]);
    this.vitalSignService.vitalSignDataSub.next([]);
  }

  viewPatient(patientId: string) {
    this.router.navigate(['/ehr/admission-profile'], {
      queryParams: {
        patientId: patientId,
      },
    });
  }

  disabledEndDate = (endValue: Date): boolean => {
    if (!endValue) {
      return false;
    }
    return endValue.getTime() > new Date().getTime();
  };

  exportAdmission() {
    this.patientService.exportAdmissionData(this.setSearchObj());
  }
  registerPatient() {
    this.router.navigate(['/ehr/register-patient']);
  }
  openRejectModal(element: any): void {
    const modal: NzModalRef<AdmissionRejectModalComponent> = this.modal.create({
      nzTitle: 'Reject Admission',
      nzContent: AdmissionRejectModalComponent,
      nzData: {
        patientId: element.id,
        rejectReasonsList: this.dropdownData.rejectedReasons,
      },
      nzFooter: null,
      nzClosable: false,
    });
    modal.getContentComponent().rejectSuccess.subscribe(() => {
      this.searchPatientsForm();
    });
  }

  openAdmitModal(element: any) {
    const modal: NzModalRef<AdmissionAdmitModalComponent> = this.modal.create({
      nzTitle: this.translate.instant('Admit Patient'),
      nzContent: AdmissionAdmitModalComponent,
      nzFooter: null,
      nzClosable: false,
      nzData: {
        patientId: element.id,
        bedsList: this.dropdownData.bedAvailableId,
        admissionReasonsList: this.dropdownData.admissionReasons,
      },
    });

    modal.getContentComponent().admitSuccess.subscribe(() => {
      this.searchPatientsForm();
    });
  }

  preApprovePatient(element: any) {
    Swal.fire({
      text: `Are you sure that you want to add the following individual to the Waiting List: ${element.firstName} ${element?.lastName}?`,
      showDenyButton: false,
      showCancelButton: true,
      cancelButtonText: `${this.translate.instant('Cancel')}`,
      confirmButtonText: `${this.translate.instant('Ok')}`,
    }).then((result) => {
      if (result.isConfirmed && element?.id) {
        this.admissionService.waitlistPatient(element?.id).subscribe({
          next: (res) => {
            if (element) {
              element.registrationStatusId = 3; // Update the local state immediately
              element.registrationStatus = 'Waiting List'; // Update the local state immediately
              this.searchPatientsForm();
            }
            this.cdr.detectChanges(); // Manually trigger change detection
            Swal.fire({
              text: 'Patient added to Waiting List successfully!',
              toast: true,
              position: 'bottom-end',
              showCancelButton: false,
              showConfirmButton: false,
              color: 'white',
              background: '#0d9488',
              timer: 3000,
            });
          },
          error: (err) => {
            Swal.fire({
              text: 'Unable to add patient to Waiting List!',
              toast: true,
              position: 'bottom-end',
              showCancelButton: false,
              showConfirmButton: false,
              color: 'white',
              background: '#ff6969',
              timer: 3000,
            });
            console.error('Error adding patient to Waiting List:', err);
          },
        });
      }
    });
  }

  openModal(item: any) {
    this.openModalID = -1;
    setTimeout(() => {
      this.openModalID = item.id;
    }, 100);
  }
}
