import { Component } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import Swal from 'sweetalert2';
import { IVaccinationDetailed } from '../../../../../../interfaces/Vaccination/IVaccinationDetailed';
import { VaccinationService } from '../../../../../../services/vaccination.service';
import { VaccinationAddEditComponent } from './vaccination-add-edit/vaccination-add-edit.component';
import { AllergiesExpandComponent } from '../../alerts-section/allergies/allergies-expand/allergies-expand.component';
import { VaccinationExpandComponent } from './vaccination-expand/vaccination-expand.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-vaccination',
  templateUrl: './vaccination.component.html',
  styleUrls: ['./vaccination.component.css'],
})
export class VaccinationComponent {
  protected readonly AllergiesExpandComponent = AllergiesExpandComponent;
  public addComp = VaccinationAddEditComponent;
  public expandComp = VaccinationExpandComponent;

  private destroy$ = new Subject<void>();

  vaccinations?: IVaccinationDetailed[];
  isLoading: boolean = false;

  constructor(
    private readonly vaccinationService: VaccinationService,
    private readonly translate: TranslateService
  ) {}

  ngOnInit() {
    this.vaccinationService.vaccinationLoadingSub
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: boolean) => {
        this.isLoading = data;
      });

    this.vaccinationService.vaccinationDataSub
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: IVaccinationDetailed[]) => {
        this.vaccinations = data;
      });

    this.vaccinationService.getData();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  openModalFun(item?: IVaccinationDetailed) {
    this.vaccinationService.vaccinationModalSub.next(true);
    this.vaccinationService.vaccinationEditSub.next(item);
  }

  deleteFun(vaccinationId: number) {
    Swal.fire({
      text: `${this.translate.instant(
        'deletePlaceholder5'
      )} ${this.translate.instant('Vaccination')}?`,
      showDenyButton: false,
      showCancelButton: true,
      cancelButtonText: `${this.translate.instant('Cancel')}`,
      confirmButtonText: `${this.translate.instant('Ok')}`,
    }).then((result) => {
      if (result.isConfirmed) {
        this.vaccinationService
          .deleteVaccination(vaccinationId.toString())
          .subscribe({
            next: (d) => {
              Swal.fire({
                text: 'Vaccination deleted successfully!',
                toast: true,
                position: 'bottom-end',
                showCancelButton: false,
                showConfirmButton: false,
                color: 'white',
                background: '#0d9488',
                timer: 3000,
              });

              this.vaccinationService.deleteVaccinationFromTable(
                vaccinationId.toString()
              );
            },
            error: (err) => {
              Swal.fire({
                text: 'Unable to delete Vaccination!',
                toast: true,
                position: 'bottom-end',
                showCancelButton: false,
                showConfirmButton: false,
                color: 'white',
                background: '#ff6969',
                timer: 3000,
              });
              console.log(err);
            },
          });
      } else if (result.isDenied) {
        Swal.fire('Vaccination is not deleted', '', 'info');
      }
    });
  }
}
