import {
  ChangeDetectorRef,
  Component,
  HostListener,
  ViewChild,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { IModule } from 'src/app/interfaces/IModule';
import { IPatientDetailed } from 'src/app/interfaces/Detailed/IPatientDetailed';
import { selectMenu } from 'src/app/selectors/menu.selector';
import { PatientService } from 'src/app/services/patient.service';
import { EpisodeOfCareService } from 'src/app/services/episodeOfCare.service';
import { Router } from '@angular/router';
import { paths } from 'src/component-paths/component-paths';
import { modules } from 'src/module-ids/module-ids';
import { CanComponentLeave } from 'src/app/guards/can-leave.guard';
import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';
import { DynamicQuestionnaireService } from 'src/app/services/dynamicQuestionnaire.service';
import { HysteroscopyStartExaminationComponent } from '../components/hysteroscopy-section/hysteroscopy-start-examination/hysteroscopy-start-examination.component';
import { Subscription } from 'rxjs';
import { HysteroscopyFileService } from 'src/app/services/hyseroscopyFile.service';

@Component({
  selector: 'app-episode-of-care',
  templateUrl: './episode-of-care.component.html',
  styleUrls: ['./episode-of-care.component.css'],
})
export class EpisodeOfCareComponent implements CanComponentLeave {
  private subscriptions: Subscription[] = [];
  public menuData?: IModule;
  public selectedOption?: number;
  public isCollapsed: boolean = false;
  public isPreview: boolean = false;
  hasUnsavedQuestionnaireChanges: boolean = false;
  ongoingHysteroscopyExamination: boolean = false;

  componentPaths = paths;

  constructor(
    private readonly patientService: PatientService,
    private readonly store: Store,
    private readonly cdr: ChangeDetectorRef,
    private readonly eocService: EpisodeOfCareService,
    private readonly router: Router,
    private readonly translate: TranslateService,
    private readonly questionnaireService: DynamicQuestionnaireService,
    private hysteroscopyFileService: HysteroscopyFileService
  ) {}

  canDeactivate(): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      Swal.fire({
        text: `${this.translate.instant('You are about to leave this visit!')}`,
        showDenyButton: true,
        showCancelButton: false,
        showCloseButton: true,
        confirmButtonText: `${this.translate.instant('Save as Draft')}`,
        denyButtonText: `${this.translate.instant('Discard Visit')}`,
        input: 'text',
        inputPlaceholder: `${this.translate.instant('Note...')}`,
      }).then((result) => {
        if (result.isConfirmed) {
          this.saveDraftVisit(result.value)
            .then(() => {
              resolve(true);
            })
            .catch(() => {
              resolve(false);
            });
        } else if (result.isDenied) {
          this.discardVisit()
            .then(() => {
              resolve(true);
            })
            .catch(() => {
              resolve(false);
            });
        } else {
          resolve(false);
        }
      });
    });
  }

  @HostListener('window:beforeunload', ['$event'])
  handleBeforeUnload(event: BeforeUnloadEvent) {
    event.preventDefault();
    event.returnValue = '';
  }

  saveDraftVisit(value: string): Promise<void> {
    return new Promise((resolve, reject) => {
      this.eocService.saveDraftVisit(value).subscribe({
        next: () => {
          Swal.fire({
            text: 'Visit saved as draft successfully!',
            toast: true,
            position: 'bottom-end',
            showCancelButton: false,
            showConfirmButton: false,
            color: 'white',
            background: '#0d9488',
            timer: 3000,
          });
          resolve();
        },
        error: () => {
          Swal.fire({
            text: 'Unable to save visit as draft!',
            toast: true,
            position: 'bottom-end',
            showCancelButton: false,
            showConfirmButton: false,
            color: 'white',
            background: '#ff6969',
            timer: 3000,
          });
          reject();
        },
      });
    });
  }

  discardVisit(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.eocService
        .deleteVisit(this.eocService.currentVisitId().toString())
        .subscribe({
          next: () => {
            Swal.fire({
              text: 'Visit was discarded successfully!',
              toast: true,
              position: 'bottom-end',
              showCancelButton: false,
              showConfirmButton: false,
              color: 'white',
              background: '#0d9488',
              timer: 3000,
            });

            resolve();
          },
          error: (err) => {
            Swal.fire({
              text: 'Unable to discard the visit!',
              toast: true,
              position: 'bottom-end',
              showCancelButton: false,
              showConfirmButton: false,
              color: 'white',
              background: '#ff6969',
              timer: 3000,
            });

            reject();
          },
        });
    });
  }

  public patientID: string = '';

  public patientInfo?: IPatientDetailed;

  toggleCollapsed(): void {
    this.isCollapsed = !this.isCollapsed;
  }

  togglePreview(value: boolean) {
    this.isPreview = value;
    this.cdr.detectChanges();
  }

  updateSelectedOption(option: number) {
    if (this.hasUnsavedQuestionnaireChanges) {
      Swal.fire({
        text: 'You have unsaved changes in the questionnaire. Please submit to continue.',
        icon: 'warning',
        showConfirmButton: true,
      });
      return;
    }
    this.selectedOption = option;
    this.cdr.detectChanges();
  }

  clickItem(m: IModule) {
    if (
      this.hasUnsavedQuestionnaireChanges &&
      m.id != 21 /*id of social history*/
    ) {
      Swal.fire({
        text: 'You have unsaved changes in the questionnaire. Please submit to continue.',
        icon: 'warning',
        showConfirmButton: true,
      });
      return;
    }
    if (m.path != this.componentPaths.pilotStudy) {
      this.updateSelectedOption(m.id);
      this.scrollToSection(m.path);
    }
  }

  scrollToSection(sectionId: string): void {
    if (
      this.hasUnsavedQuestionnaireChanges &&
      sectionId != 'socialHistory' /*id of social history*/
    ) {
      Swal.fire({
        text: 'You have unsaved changes in the questionnaire. Please submit to continue.',
        icon: 'warning',
        showConfirmButton: true,
      });
      return;
    }
    const element = document.getElementById(sectionId);
    if (element) {
      // menu, patient header, title, margin
      const offset = element.offsetTop - 120 - 66 - 64 - 16;
      window.scroll({
        top: offset,
        behavior: 'smooth',
      });
    }
  }

  activeSubmenu(): string {
    return (
      this.menuData?.childModules?.find((d) =>
        d.childModules.find((x) => x.id === this.selectedOption)
      )?.path ||
      this.menuData?.childModules?.find((d) => d.id === this.selectedOption)
        ?.path ||
      ''
    );
  }

  getSubmenu(menu: string): IModule[] | undefined {
    return this.menuData?.childModules?.find((d) => d.path === menu)
      ?.childModules;
  }

  getGynecologySubMenu(menu: string): IModule[] | undefined {
    const foundModule = this.menuData?.childModules?.find(
      (d) => d.path === menu
    )?.childModules;

    if (foundModule) {
      const childWithSubmodules = foundModule.find(
        (child) => child.childModules && child.childModules.length > 0
      );
      return childWithSubmodules
        ? childWithSubmodules.childModules
        : foundModule;
    }
    return undefined;
  }

  ngOnInit() {
    this.subscriptions.push(
      this.hysteroscopyFileService
        .getExaminationStatus()
        .subscribe((status: boolean) => {
          if (status) {
            this.ongoingHysteroscopyExamination = status;
          } else {
            this.ongoingHysteroscopyExamination = false;
          }
        })
    );
    if (window.innerWidth <= 1919) {
      this.isCollapsed = true;
    }

    this.isPreview = false;
    this.patientID = this.patientService.patientIDsignal();
    this.patientService.patientLoadingSub.next(true);

    this.patientService.getPatientByID(this.patientID).subscribe({
      next: (response) => {
        this.patientService.patientInfoSub.next(response?.data!);
        this.patientService.patientLoadingSub.next(true);
        this.patientInfo = response?.data; // Assuming data is the property you want

        this.store.select(selectMenu).subscribe((data) => {
          let profileModule = data
            ?.find((d) => d.path == 'allPatients')
            ?.childModules?.find((d) => d.path === 'profile')!;

          if (!profileModule) {
            profileModule = data
              ?.find((d) => d.path == 'allPatientsPilotStudy')
              ?.childModules?.find((d) => d.path === 'profile')!;
          }

          // Remove Episode of Care TAB (ID: 36)
          profileModule = {
            ...profileModule,
            childModules:
              profileModule?.childModules?.filter(
                (c) => c.id != modules.episodeOfCare
              ) || [],
          };

          // If not imported
          // Remove IPS preview TAB (ID: 26)
          if (!this.patientInfo?.isImported) {
            profileModule = {
              ...profileModule,
              childModules:
                profileModule?.childModules?.filter(
                  (c) => c.id != modules.ipsPreview
                ) || [],
            };
          }

          // If not female
          // Remove Gynecology TAB (ID: 51)
          if (this.patientInfo?.gender?.toLowerCase() != 'female') {
            profileModule = {
              ...profileModule,
              childModules:
                profileModule?.childModules?.filter(
                  (c) => c.id != modules.gynecology
                ) || [],
            };
          }

          // If not inpatient
          // Remove Daily Monitoring TAB (ID: 28)
          // Remove Etiology TAB (ID: 45)
          // Remove Complication TAB (ID: 46)
          // Remove Tracheostomy 2 TAB (ID: 49) ?
          if (!this.isInpatientView()) {
            profileModule = {
              ...profileModule,
              childModules:
                profileModule?.childModules?.filter(
                  (c) =>
                    c.id != modules.dailyMonitoring &&
                    c.id != modules.etiology &&
                    c.id != modules.complication
                ) || [],
            };
          }

          this.menuData = profileModule;
        });

        // if the first option is pilot study (44)
        // have tracheostomy2 (49) as preselected
        if (this.menuData?.childModules?.at(0)?.id == modules.pilotStudy) {
          this.selectedOption = modules.tracheostomy2;
          // GOC - if the first option is pilot study (55)
          // have tracheostomy2 (56) as preselected
        } else if (
          this.menuData?.childModules?.at(0)?.id == modules.gocPilotStudy
        ) {
          this.selectedOption = modules.gocTracheostomy;
        } else if (
          this.menuData?.childModules?.at(0)?.childModules.length! == 0
        ) {
          this.selectedOption = this.menuData?.childModules?.at(0)?.id;
        } else {
          this.selectedOption =
            this.menuData?.childModules?.at(0)?.childModules?.at(0)?.id ||
            this.menuData?.childModules?.at(0)?.id ||
            this.menuData?.id ||
            0;
        }

        this.patientService.patientLoadingSub.next(false);
      },
      error: (err) => {
        console.error('Error fetching patient information:', err);
      },
    });
    this.questionnaireService.getUnsavedChanges().subscribe((state) => {
      this.hasUnsavedQuestionnaireChanges = state;
    });
  }

  isInpatientView() {
    return (
      !(
        this.eocService.currentEocId() === null ||
        this.eocService.currentEocId() === ''
      ) && this.router.url.includes('inPatientView')
    );
  }
}
