<nz-layout>
    <nz-content>
        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" class="my-patients-content">
            <div fxFlex="25" fxFlex.lt-md="100" fxLayout="col" class="patient-list">
                <nz-layout class="content-layout">
                    <nz-content class="content-patients">
                        <div fxLayout="row" fxLayoutGap="4px" fxLayoutAlign="center center" class="search-bar">
                            <div fxLayout="column" fxFlex="75">
                                <input nz-input type="text" class="w-100 search-input" #searchInput>
                            </div>
                            <div fxLayout="column" fxFlex="25">
                                <button class="w-100 search-btn" (click)="pageChange(searchInput.value)">
                                    {{'Search' | translate}}</button>
                            </div>
                        </div>

                        <mat-table [dataSource]="data" class="mat-elevation-z8">

                            <ng-container matColumnDef="profile">
                                <mat-header-cell *matHeaderCellDef mat-sort-header>
                                    {{ 'Patients' | translate }}
                                </mat-header-cell>
                                <mat-cell *matCellDef="let element">
                                    <div fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="center center">
                                        <div fxLayout="column">
                                            <nz-avatar [nzSize]="64" [nzSrc]="profilePicture[element?.id]"></nz-avatar>
                                        </div>

                                        <div fxLayout="column">
                                            <div fxLayout="row" fxFlex="100" class="patient-name">{{element?.firstName}}
                                                {{element?.lastName}}
                                            </div>
                                            <div fxLayout="row" fxFlex="100">{{element?.documentNumber}}</div>
                                        </div>
                                    </div>
                                </mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="age">
                                <mat-header-cell *matHeaderCellDef mat-sort-header>
                                    {{ 'Date of Birth' | translate }}
                                </mat-header-cell>
                                <mat-cell mat-cell *matCellDef="let element"> {{element.dateOfBirth || "-"}} </mat-cell>
                            </ng-container>

                            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                            <mat-row *matRowDef="let row; columns: displayedColumns;"
                                (click)="onPatientClick(row?.id)"></mat-row>
                        </mat-table>

                        <span>
                            <div fxLayout="column" fxLayoutAlign="center center" fxFlex="100"
                                *ngIf="data.data.length == 0" class="no-patients">
                                No Patients available.
                            </div>
                        </span>

                        <mat-paginator #paginator [pageSizeOptions]="getTableSize()" showFirstLastButtons
                            [ngClass]="data.data.length===0 ? 'patients-content-paginator-hide no-paginator' : ''"
                            (page)="pageChange()" [length]="totalRecords" />

                    </nz-content>
                </nz-layout>
            </div>
            <div fxFlex="75" fxFlex.lt-md="100" fxLayout="col" class="patient-details">
                <nz-layout class="content-layout">
                    <nz-content class="content-body" *ngIf="selectedPatientID && !isLoading">

                        <ng-container>
                            <app-reusable-table title="Medication"
                                [columns]="['Onset Date','End Date','Product','Package', 'Unit', 'Frequency','Duration']"
                                [rowFields]="['onSetDate','endDate','productCombination','packageCombination', 'unitCombination','frequencyCombination','durationTreatmentCombination']"
                                [data]="medications.getAll" [dataGetList]="medications" [pagination]="false"
                                [isPreview]="true" [isLoading]="isLoading" />
                        </ng-container>

                        <ng-container>
                            <app-reusable-table title="Allergies" [columns]="['Category',
                        'Type',
                        'Substance/Product',
                        'Criticality'
                    ,'Status']" [rowFields]="['allergyCategory',
                        'eventType',
                        'allergy',
                        'criticality'
                        ,'allergyStatus']" [data]="allergies.getAll" [dataGetList]="allergies" [pagination]="false"
                                [expandComponent]="allergiesExpandComp" [isPreview]="true" [isLoading]="isLoading" />
                        </ng-container>

                        <ng-container>
                            <app-reusable-table title="Problems"
                                [columns]="['Problem','Diagnosis Assertion','Resolution Circumstances','End Date','Status']"
                                [rowFields]="['problem','diagnosisAssertion','resolutionCircumstancesText','endDate','statusCode']"
                                [data]="problems.getAll" [dataGetList]="problems" [pagination]="false"
                                [isPreview]="true" [isLoading]="isLoading" />
                        </ng-container>


                    </nz-content>
                </nz-layout>
            </div>
        </div>

    </nz-content>
</nz-layout>