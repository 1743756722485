<div class="cropper-container">
  <h2>Upload Profile Picture</h2>
  <p>Please edit the image dimensions and upload the profile picture</p>
  <image-cropper
    style="margin-bottom: 20px"
    #cropper
    [imageChangedEvent]="imageChangedEvent"
    (imageCropped)="imageCroppedHandler($event)"
    [maintainAspectRatio]="true"
    [aspectRatio]="1"
    [resizeToWidth]="450"
    [resizeToHeight]="450"
  >
  </image-cropper>
  <div
    fxLayout="row"
    fxLayoutAlign="center center"
    fxLayoutGap="20px"
    class="row-size"
  >
    <button class="cancel-image-btn" (click)="closeDialogBox()">Cancel</button>
    <button class="upload-image-btn" (click)="cropAndUploadImage()">
      Upload Image
    </button>
  </div>
</div>
