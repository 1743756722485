import {
  ChangeDetectorRef,
  Component,
  HostListener,
  OnInit,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { IAddQuestionnaireAnswers } from 'src/app/interfaces/Questionnaire/IAddQuestionnaireAnswers';
import {
  IQuestionnairePage,
  IQuestionnaireQuestion,
} from 'src/app/interfaces/Questionnaire/IQuestionnaire';
import { IGetAnswerQuestionnaire } from 'src/app/interfaces/Questionnaire/IQuestionnaireAnswers';
import { IQuestionnaireDetailed } from 'src/app/interfaces/Questionnaire/IQuestionnaireDetailed';
import { DynamicQuestionnaireService } from 'src/app/services/dynamicQuestionnaire.service';
import { EpisodeOfCareService } from 'src/app/services/episodeOfCare.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-dynamic-questionnaire',
  templateUrl: './dynamic-questionnaire.component.html',
  styleUrls: ['./dynamic-questionnaire.component.css'],
})
export class DynamicQuestionnaireComponent implements OnInit {
  formGroup: FormGroup;
  pages: IQuestionnairePage[] = [];
  answers!: IGetAnswerQuestionnaire;
  data!: IQuestionnaireDetailed;
  current = 0;
  disable = false;
  isLargeScreen = true;
  isLoading = false;
  formInitialized = false;

  constructor(
    private fb: FormBuilder,
    private questionnaireService: DynamicQuestionnaireService,
    private readonly eocService: EpisodeOfCareService,
    private readonly router: Router,
    private readonly cdr: ChangeDetectorRef
  ) {
    this.formGroup = this.fb.group({});
  }
  @HostListener('window:resize', ['$event'])
  onResize(event?: Event): void {
    this.isLargeScreen = window.innerWidth >= 1900;
  }

  isInpatientView(): boolean {
    return (
      !(
        this.eocService.currentEocId() === null ||
        this.eocService.currentEocId() === ''
      ) && this.router.url.includes('inPatientView')
    );
  }

  isEOC() {
    return (
      !(
        this.eocService.currentEocId() === null ||
        this.eocService.currentEocId() === ''
      ) && this.router.url.includes('episodeOfCare')
    );
  }

  ngOnInit() {
    this.isLoading = true;
    this.onResize();
    this.getData();
    this.formGroup.valueChanges.subscribe(() => {
      if (this.formInitialized) {
        this.checkFormChanges();
      }
    });
  }

  checkFormChanges(): void {
    let hasChanges = false;

    Object.keys(this.formGroup.controls).forEach((key) => {
      const control = this.formGroup.get(key);
      if (control && control.dirty) {
        hasChanges = true;
      }
    });

    this.questionnaireService.setUnsavedChanges(hasChanges);
  }

  getData(): void {
    this.questionnaireService
      .getQuestionnaireData(1) //questionnaire template id (inpatient =1 , outpatient=2) code: this.isInpatientView() || isEOC ? 1 : 2
      .subscribe((data: IQuestionnaireDetailed) => {
        if (data && data.questionnaire) {
          this.data = data;
          this.pages = data.questionnaire.pages;
          this.pages.forEach((page) => {
            page.elements.forEach((question) => {
              this.formGroup.addControl(
                question.name,
                this.fb.control({
                  value: '',
                  disabled: !this.isInpatientView() && !this.isEOC(),
                })
              );
              // If there are answers in the response prefill the questionnaire
              if (data.answers) {
                data.answers.answer.forEach((ans) => {
                  if (ans.questionTemplateId === question.id) {
                    // Check if the question type is boolean and convert the value accordingly
                    let value =
                      ans.questionType.toLowerCase() === 'boolean'
                        ? ans.value === 'true'
                        : ans.value;

                    this.formGroup
                      .get(question.name as string)
                      ?.setValue(value);
                  }
                });
              }
            });
          });
        }
        this.isLoading = false; // <-- Set loading to false after data is loaded
        this.formInitialized = true;
        this.cdr.detectChanges();
      });
  }

  pre(event: Event): void {
    event.preventDefault(); // Prevent the form from being submitted
    if (this.current > 0) {
      this.current -= 1;
    }
  }

  next(event: Event): void {
    event.preventDefault(); // Prevent the form from being submitted
    if (this.current < this.pages.length - 1) {
      this.current += 1;
    }
  }

  onIndexChange(index: number): void {
    this.current = index;
    this.cdr.detectChanges();
  }

  onSubmit(): void {
    const formValues = this.formGroup.value;
    const transformedValues: { [key: string]: any } = {};

    this.pages.forEach((page) => {
      page.elements.forEach((element: IQuestionnaireQuestion) => {
        const value = formValues[element.name];
        // Check if the value is not null, undefined, or an empty string
        if (value !== undefined && value !== null && value !== '') {
          if (typeof value === 'boolean') {
            transformedValues[element.id.toString()] = value.toString();
          } else {
            transformedValues[element.id.toString()] = value;
          }
        }
      });
    });

    const qaObj: IAddQuestionnaireAnswers = {
      questionnaireTemplateId: 1, //questionnaire template id (inpatient =1 , outpatient=2) code: this.isInpatientView() || isEOC ? 1 : 2
      questionnaireData: transformedValues,
    };
    this.questionnaireService.addQuestionnaireAnswers(qaObj).subscribe({
      next: () => {
        Swal.fire({
          text: 'Questionnaire answered successfully!',
          toast: true,
          position: 'bottom-end',
          showCancelButton: false,
          showConfirmButton: false,
          color: 'white',
          background: '#0d9488',
          timer: 3000,
        });
        this.formGroup.markAsPristine();
        this.questionnaireService.setUnsavedChanges(false);
        this.getData();
      },
      error: (err) => {
        Swal.fire({
          text: 'Unable to add Questionnaire answers!',
          toast: true,
          position: 'bottom-end',
          showCancelButton: false,
          showConfirmButton: false,
          color: 'white',
          background: '#ff6969',
          timer: 3000,
        });
        console.log(err);
      },
    });
  }
}
