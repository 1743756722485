<div *ngIf="!loading" class="edit-user-container">
  <h2 class="titleBar">{{ "Edit User" | translate }}</h2>
  <form
    [formGroup]="formGroup"
    (ngSubmit)="saveChanges()"
    class="edit-user-form"
  >
    <div fxLayout="row" fxLayoutAlign="center center" class="label-row">
      <div fxLayout="col" fxFlex="50" class="add-patient-col">
        {{ "First name" | translate }}
        <span class="required-asterisk">*</span>
      </div>
      <div fxLayout="col" fxFlex="50" class="add-patient-col">
        {{ "Last name" | translate }}
        <span class="required-asterisk">*</span>
      </div>
    </div>

    <div fxLayout="row" fxLayoutAlign="center center" class="add-patient-row">
      <div fxLayout="col" fxFlex="50" class="add-patient-col">
        <input
          class="add-patient-input"
          [placeholder]="'First name' | translate"
          formControlName="firstName"
        />
      </div>
      <div fxLayout="col" fxFlex="50" class="add-patient-col">
        <input
          class="add-patient-input"
          [placeholder]="'Last name' | translate"
          formControlName="lastName"
        />
      </div>
    </div>

    <div fxLayout="row" fxLayoutAlign="center center" class="label-row">
      <div fxLayout="col" fxFlex="50" class="add-patient-col">
        {{ "Username" | translate }}
        <span class="required-asterisk">*</span>
      </div>
      <div fxLayout="col" fxFlex="50" class="add-patient-col">
        {{ "Phone Number" | translate }}
        <span class="required-asterisk">*</span>
      </div>
    </div>

    <div fxLayout="row" fxLayoutAlign="center center" class="add-patient-row">
      <div fxLayout="col" fxFlex="50" class="add-patient-col">
        <input
          class="add-patient-input"
          [placeholder]="'Username' | translate"
          formControlName="username"
        />
      </div>
      <div fxLayout="col" fxFlex="50" class="add-patient-col">
        <ngx-intl-tel-input
          [cssClass]="'custom'"
          [preferredCountries]="[CountryISO.Cyprus, CountryISO.Greece]"
          [enableAutoCountrySelect]="true"
          [enablePlaceholder]="false"
          [searchCountryFlag]="true"
          [searchCountryField]="[
            SearchCountryField.Iso2,
            SearchCountryField.Name
          ]"
          [selectFirstCountry]="false"
          [maxLength]="15"
          [phoneValidation]="true"
          [separateDialCode]="false"
          name="phone"
          formControlName="phoneNumber"
        ></ngx-intl-tel-input>
      </div>
    </div>

    <div fxLayout="row" fxLayoutAlign="center center" class="label-row">
      <div fxLayout="col" fxFlex="100" class="add-patient-col">
        {{ "Email address" | translate }}
        <span class="required-asterisk">*</span>
      </div>
    </div>

    <div fxLayout="row" fxLayoutAlign="center center" class="add-patient-row">
      <div fxLayout="col" fxFlex="100" class="add-patient-col">
        <input
          class="add-patient-input"
          [placeholder]="'Email address' | translate"
          formControlName="email"
        />
      </div>
    </div>

    <div fxLayout="row" fxLayoutAlign="center center" class="label-row">
      <div fxLayout="col" fxFlex="50" class="add-patient-col">
        {{ "Role" | translate }}
        <span class="required-asterisk">*</span>
      </div>
      <div fxLayout="col" fxFlex="50" class="add-patient-col">
        {{ "Language" | translate }}
        <span class="required-asterisk">*</span>
      </div>
    </div>

    <div fxLayout="row" fxLayoutAlign="center center" class="add-patient-row">
      <div fxLayout="col" fxFlex="50" class="multiselector">
        <nz-select
          nzMode="multiple"
          class=""
          style="width: 100%"
          formControlName="roles"
          nzPlaceHolder="{{ 'Roles' | translate }}"
        >
          <option disabled selected>{{ "Role" | translate }}</option>
          <ng-container *ngFor="let role of roles">
            <nz-option [nzLabel]="role.name" [nzValue]="role.id"></nz-option>
          </ng-container>
        </nz-select>
      </div>

      <div fxLayout="col" fxFlex="50" class="add-patient-col">
        <select class="add-patient-input" formControlName="locale">
          <option disabled selected>{{ "Language" | translate }}</option>
          <ng-container *ngFor="let locale of locales">
            <option [value]="locale.id">{{ locale.name }}</option>
          </ng-container>
        </select>
      </div>
    </div>

    <div class="buttons" fxFlex="row">
      <div fxFlex="col" fxLayout="50"></div>
      <div fxFlex="col" fxLayout="50" style="width: 100%">
        <button mat-raised-button class="save-button" type="submit" fxFlex>
          {{ "Save Changes" | translate }}
        </button>

        <button mat-raised-button class="cancel-button" (click)="cancelEdit()">
          {{ "Cancel" | translate }}
        </button>
      </div>
    </div>

    <div class="mail-section" fxFlex="row">
      <div fxFlex="col" fxLayout="50"></div>
      <div fxFlex="col" fxLayout="50">
        <!-- Show the 'Send Mail' button only if requiredActions is true -->
        <button
          *ngIf="editedUser.requiredActions"
          mat-raised-button
          color="warn"
          class="email-button"
          (click)="sendMail()"
          fxFlex
        >
          {{ "Send Via Mail Account Credentials" | translate }}
        </button>
      </div>
    </div>
  </form>
</div>

<div *ngIf="loading" class="loading-container">
  <!-- Centered loading spinner -->
  <div class="loading-text">
    <p>{{ "Loading..." | translate }}</p>
  </div>
  <mat-spinner></mat-spinner>
</div>
