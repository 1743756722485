<nz-layout class="full-height">
  <nz-header>
    <div class="header-content">
      <div class="header-title">
        {{ "Patient's Admission Profile" | translate }}

        <span *ngIf="
            admissionPersonalInfoData?.firstName &&
            admissionPersonalInfoData?.lastName
          ">: {{ admissionPersonalInfoData?.firstName }}
          {{ admissionPersonalInfoData?.lastName }}</span>
        <button *ngIf="!isEditMode" nz-button class="admission-edit-button" (click)="enterEditMode()">
          <i nz-icon nzType="edit"></i>
          {{ "Edit" | translate }}
        </button>
      </div>

      <div class="header-buttons">
        <button *ngIf="isEditMode" nz-button class="admission-edit-button" (click)="updatePatientInfo()">
          <i nz-icon nzType="check"></i>
          {{ "Update" | translate }}
        </button>
        <button *ngIf="isEditMode" nz-button class="admission-cancel-button" (click)="requestCancel()">
          <i nz-icon nzType="stop"></i>
          {{ "Cancel" | translate }}
        </button>

        <button *ngIf="patientInfo?.registrationStatusId == 4 && !isEditMode" (click)="waitlistPatient()" nz-button
          class="admission-waitinglist-button">
          {{ "Waiting List" | translate }}
        </button>
        <button *ngIf="patientInfo?.registrationStatusId == 3 && !isEditMode" (click)="openAdmitModal()" nz-button
          class="admission-accept-button">
          {{ "Admit" | translate }}
        </button>

        <button nz-button *ngIf="
            patientInfo?.registrationStatusId != 5 &&
            patientInfo?.registrationStatusId != 2 &&
            !isEditMode &&
            isLoading == 3
          " (click)="openRejectModal()" class="admission-reject-button">
          {{ "Reject" | translate }}
        </button>
      </div>
    </div>
  </nz-header>
  <nz-content>
    <nz-tabset nzType="card">
      <nz-tab [nzTitle]="titleTemplate1">
        <ng-template #titleTemplate1>
          <i nz-icon nzType="icons:personalInfo"></i>
          {{ "Personal Information" | translate }}
        </ng-template>
        <div>
          <app-admission-personal-info [admissionPersonalInfoData]="admissionPersonalInfoData"
            [gendersList]="gendersList" [bloodTypesList]="bloodTypesList" [doctors]="doctors"
            [placeOfBirthList]="placeOfBirthList" [closestRelativesList]="closestRelativesList"
            [educationLevelList]="educationLevelList" [familyStatusList]="familyStatusList"
            [sourceOfIncomeList]="sourceOfIncomeList" [religionList]="religionList" [formSubmitted]="formSubmitted"
            [registrationStatusDropdown]="
              admissionDropdownData.registrationStatus!
            " [documentTypeList]="documentTypeList" [countriesList]="countriesList" [avatarImageUrl]="avatarImageUrl"
            [isEditMode]="isEditMode"></app-admission-personal-info>
        </div>
      </nz-tab>

      <nz-tab [nzTitle]="titleTemplate2">
        <ng-template #titleTemplate2>
          <i nz-icon nzType="icons:medicalInfo"></i>
          {{ "Medical Info" | translate }}
        </ng-template>
        <app-admission-medical-info [admissionMedicalInfoData]="admissionMedicalInfoData"
          [bloodTypesList]="bloodTypesList" [doctors]="doctors" [placeOfBirthList]="placeOfBirthList"
          [closestRelativesList]="closestRelativesList" [educationLevelList]="educationLevelList"
          [familyStatusList]="familyStatusList" [sourceOfIncomeList]="sourceOfIncomeList" [religionList]="religionList"
          [immobilityList]="immobilityList" [formSubmitted]="formSubmitted"
          [isEditMode]="isEditMode"></app-admission-medical-info>
      </nz-tab>

      <nz-tab [nzTitle]="titleTemplate3">
        <ng-template #titleTemplate3>
          <i nz-icon nzType="icons:addressInfo"></i>
          {{ "Address Info" | translate }}
        </ng-template>
        <app-admission-address-info [admissionAddressInfoData]="admissionAddressInfoData" [gendersList]="gendersList"
          [bloodTypesList]="bloodTypesList" [doctors]="doctors" [placeOfBirthList]="placeOfBirthList"
          [closestRelativesList]="closestRelativesList" [educationLevelList]="educationLevelList"
          [familyStatusList]="familyStatusList" [sourceOfIncomeList]="sourceOfIncomeList" [religionList]="religionList"
          [countriesList]="countriesList" [formSubmitted]="formSubmitted"
          [isEditMode]="isEditMode"></app-admission-address-info>
      </nz-tab>

      <nz-tab [nzTitle]="titleTemplate4">
        <ng-template #titleTemplate4>
          <i nz-icon nzType="icons:emergencyContacts"></i>
          {{ "Emergency Contacts" | translate }}
        </ng-template>
        <app-admission-emergency-contacts-info [countriesList]="countriesList" [documentTypeList]="documentTypeList"
          [admissionEmergencyContactsInfoData]="
            admissionEmergencyContactsInfoData
          " [closestRelativesList]="closestRelativesList" [formSubmitted]="formSubmitted"
          [isEditMode]="isEditMode"></app-admission-emergency-contacts-info>
      </nz-tab>

      <nz-tab [nzTitle]="titleTemplate5">
        <ng-template #titleTemplate5>
          <i nz-icon nzType="icons:insurance"></i>
          {{ "Insurance" | translate }}
        </ng-template>
        <app-admission-insurance [admissionInsuranceInfoData]="admissionInsuranceInfoData" [insurances]="insuranceList"
          [formSubmitted]="formSubmitted" [isEditMode]="isEditMode">
        </app-admission-insurance>
      </nz-tab>

      <nz-tab [nzTitle]="titleTemplate6" *ngIf="isPatientInfoLoaded && isNotPending() && isNotRejected()">
        <ng-template #titleTemplate6>
          <i nz-icon nzType="icons:documents"></i>
          {{ "Documents" | translate }}
        </ng-template>
        <app-admission-documents [fileList]="fileList" [isEditMode]="isEditMode" />

        <!-- <app-admission-documents /> -->
      </nz-tab>
    </nz-tabset>
  </nz-content>
</nz-layout>