<div class="patients-container">
  <form [formGroup]="searchForm">
    <div class="patients-search">
      <div fxLayout="column" fxLayoutAlign="center center" fxFlex="1" class="">
        <label nz-checkbox (ngModelChange)="onSelectAllChange($event)" formControlName="selectAll" style="width: 100%"
          nz-tooltip="{{ 'Select All' | translate }}"></label>
      </div>
      <div fxLayout="column" fxLayoutAlign="center start" fxFlex="21" class="patients-search-input">
        <nz-select class="registration" nzMode="multiple" nzShowSearch nzAllowClear
          [nzPlaceHolder]="'Admission Status' | translate" formControlName="registrationValue" [nzLoading]="isLoading"
          style="width: 100%" [nzDisabled]="searchForm.get('selectAll')?.value">
          <ng-container *ngFor="let et of dropdownData.registrationStatus">
            <nz-option [nzLabel]="et.name" [nzValue]="et.id"></nz-option>
          </ng-container>
        </nz-select>
      </div>

      <div fxLayout="column" fxLayoutAlign="center start" fxFlex="14" class="patients-search-input">
        <nz-range-picker formControlName="dateValue" [nzDisabledDate]="disabledEndDate"
          style="width: 100%"></nz-range-picker>
      </div>
      <div fxLayout="column" fxLayoutAlign="center start" fxFlex="10" class="patients-search-input">
        <input nz-input placeholder="{{ 'Document Number' | translate }}" formControlName="documentValue" type="text"
          style="width: 100%" />
      </div>
      <div fxLayout="column" fxLayoutAlign="center center" fxFlex="3" class="ml-1">
        <label nz-checkbox formControlName="mohValue" style="width: 100%"
          nz-tooltip="{{ 'Chronic patient' | translate }}">{{ "MOH/DMSW" | translate }}</label>
      </div>

      <div fxFlex="auto"></div>
      <div fxLayout="column" fxLayoutAlign="" fxFlex="33" class="patients-search-buttons">
        <div fxLayout="row" fxFlex="100">
          <div fxLayout="column" fxLayoutAlign="" fxFlex="24">
            <button class="search-button" (click)="searchPatientsForm()">
              <mat-icon>search</mat-icon>
              <div class="btn-text">{{ "Search" | translate }}</div>
            </button>
          </div>
          <div fxFlex="6"></div>
          <div fxLayout="column" fxLayoutAlign="" fxFlex="24">
            <button class="register-button" (click)="exportAdmission()">
              <mat-icon>import_export</mat-icon>
              <div class="btn-text">{{ "Export" | translate }}</div>
            </button>
          </div>
          <div fxFlex="6"></div>
          <div fxLayout="column" fxLayoutAlign="" fxFlex="40">
            <button class="register-button" (click)="registerPatient()">
              <mat-icon>add</mat-icon>
              <div class="btn-text">{{ "Register Patient" | translate }}</div>
            </button>
          </div>
        </div>
      </div>
    </div>
  </form>
  <div class="patients-table">
    <mat-table [dataSource]="data" class="mat-elevation-z8">
      <ng-container matColumnDef="documentNumber">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ "Document Number" | translate }}
        </mat-header-cell>
        <mat-cell *matCellDef="let element">
          {{ element?.documentNumber || "-" }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="firstName">
        <mat-header-cell *matHeaderCellDef>
          {{ "First Name" | translate }}
        </mat-header-cell>
        <mat-cell mat-cell *matCellDef="let element">
          {{ element?.firstName || "-" }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="lastName">
        <mat-header-cell *matHeaderCellDef>
          {{ "Last Name" | translate }}
        </mat-header-cell>
        <mat-cell mat-cell *matCellDef="let element">
          {{ element?.lastName || "-" }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="dateOfBirth">
        <mat-header-cell *matHeaderCellDef>
          {{ "Date of Birth" | translate }}
        </mat-header-cell>
        <mat-cell mat-cell *matCellDef="let element">
          {{ element?.dateOfBirth || "-" }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="age">
        <mat-header-cell *matHeaderCellDef>
          {{ "Age" | translate }}
        </mat-header-cell>
        <mat-cell mat-cell *matCellDef="let element">
          {{ element?.age || "-" }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="dateOfApplication">
        <mat-header-cell *matHeaderCellDef>
          {{ "Date of Application" | translate }}
        </mat-header-cell>
        <mat-cell mat-cell *matCellDef="let element">
          {{ element?.dateOfApplication || "-" }}
          <span *ngIf="element.daysFromFirstRegistration" style="margin-left: 5px"
            nz-tooltip="{{ 'Days passed since registration' | translate }}">
            ({{ element.daysFromFirstRegistration }})</span>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="registrationStatus">
        <mat-header-cell *matHeaderCellDef>
          {{ "Admission Status" | translate }}
        </mat-header-cell>
        <mat-cell mat-cell *matCellDef="let element">
          <span [ngSwitch]="element?.registrationStatus">
            <span *ngSwitchCase="'Pending'" class="pending">{{
              element?.registrationStatus | translate
              }}</span>
            <span *ngSwitchCase="'Approved'" class="approved">{{ element?.registrationStatus | translate }}
              <span *ngIf="element?.reason || element?.reasonNotes">
                <i nz-icon nzType="icons:info" nzTheme="outline" (click)="openModal(element)"></i>
                <app-admission-popup [patient]="element" [openModalID]="openModalID"></app-admission-popup>
              </span></span>
            <span *ngSwitchCase="'Rejected'" class="rejected">{{ element?.registrationStatus | translate }}
              <span *ngIf="element?.reason || element?.reasonNotes">
                <i nz-icon nzType="icons:info" nzTheme="outline" (click)="openModal(element)"></i>
                <app-admission-popup [patient]="element" [openModalID]="openModalID"></app-admission-popup>
              </span></span>
            <span *ngSwitchCase="'Waiting List'" class="waitinglist">{{
              element?.registrationStatus | translate
              }}</span>
          </span>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="fileRatio">
        <mat-header-cell *matHeaderCellDef>
          {{ "Documents" | translate }}
        </mat-header-cell>
        <mat-cell *matCellDef="let element">
          <ng-container *ngIf="
              element?.registrationStatusId == 3 ||
                element.registrationStatusId == 2;
              else dash
            ">
            <span [ngStyle]="{
                color:
                  element.uploadedFileTypes === 0
                    ? 'red'
                    : element.uploadedFileTypes > 0 &&
                      element.uploadedFileTypes < 7
                    ? 'orange'
                    : element.uploadedFileTypes === 7
                    ? 'green'
                    : 'black'
              }">
              {{ element.uploadedFileTypes }} / {{ element.fileTypesCount }}
            </span>
          </ng-container>
          <ng-template #dash>-</ng-template>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="admission-actions">
        <mat-header-cell *matHeaderCellDef>
          {{ "Actions" | translate }}
        </mat-header-cell>
        <mat-cell *matCellDef="let element" class="action-buttons-cell">
          <button *ngIf="
              element.registrationStatusId != 5 &&
              element.registrationStatusId != 2 &&
              !isLoading
            " class="discharge-button" (click)="openRejectModal(element)">
            {{ "Reject" | translate }}
          </button>
          <button *ngIf="element.registrationStatusId === 4 && !isLoading" class="waitinglist-button"
            (click)="preApprovePatient(element)">
            {{ "Waiting List" | translate }}
          </button>
          <button *ngIf="element.registrationStatusId === 3 && !isLoading" class="preapprove-button"
            (click)="openAdmitModal(element)">
            {{ "Admit" | translate }}
          </button>
          <button class="add-button" (click)="viewPatient(element.id)">
            {{ "View" | translate }}
          </button>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
    </mat-table>

    <span>
      <div fxLayout="column" fxLayoutAlign="center center" fxFlex="100" *ngIf="data.data.length == 0"
        class="no-patients">
        No Patients available.
      </div>
    </span>
    <mat-paginator #inpaginator [pageSizeOptions]="[10, 25, 100]" showFirstLastButtons [ngClass]="
        data.data.length === 0 ? 'patients-content-paginator-hide' : ''
      " (page)="searchPatientsForm()" [length]="totalRecords" />
  </div>
</div>