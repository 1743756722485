import { Component } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import Swal from 'sweetalert2';
import { IMedicalInfoDetailed } from '../../../../../../interfaces/MedicalInfo/IMedicalInfoDetailed';
import { CarePlanAddEditComponent } from './care-plan-add-edit/care-plan-add-edit.component';
import { CarePlanService } from '../../../../../../services/carePlan.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-care-plan',
  templateUrl: './care-plan.component.html',
  styleUrls: ['./care-plan.component.css'],
})
export class CarePlanComponent {
  public addComp = CarePlanAddEditComponent;

  private destroy$ = new Subject<void>();

  carePlans?: IMedicalInfoDetailed[];
  isLoading: boolean = false;

  constructor(
    private readonly carePlanService: CarePlanService,
    private readonly translate: TranslateService
  ) {}

  openModalFun(item?: IMedicalInfoDetailed) {
    this.carePlanService.carePlanModalSub.next(true);
    this.carePlanService.carePlanEditSub.next(item);
  }

  deleteFun(carePlanId: number) {
    Swal.fire({
      text: `${this.translate.instant(
        'deletePlaceholder1'
      )} ${this.translate.instant('Care Plan?')}?`,
      showDenyButton: false,
      showCancelButton: true,
      cancelButtonText: `${this.translate.instant('Cancel')}`,
      confirmButtonText: `${this.translate.instant('Ok')}`,
    }).then((result) => {
      if (result.isConfirmed) {
        this.carePlanService.deleteCarePlan(carePlanId.toString()).subscribe({
          next: (d) => {
            Swal.fire({
              text: 'Care plan deleted successfully!',
              toast: true,
              position: 'bottom-end',
              showCancelButton: false,
              showConfirmButton: false,
              color: 'white',
              background: '#0d9488',
              timer: 3000,
            });

            this.carePlanService.deleteCarePlanFromTable(carePlanId.toString());
          },
          error: (err) => {
            Swal.fire({
              text: 'Unable to delete Care plan!',
              toast: true,
              position: 'bottom-end',
              showCancelButton: false,
              showConfirmButton: false,
              color: 'white',
              background: '#ff6969',
              timer: 3000,
            });
            console.log(err);
          },
        });
      } else if (result.isDenied) {
        Swal.fire('Care plan is not deleted', '', 'info');
      }
    });
  }
  ngOnInit() {
    this.carePlanService.carePlanLoadingSub
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: boolean) => {
        this.isLoading = data;
      });

    this.carePlanService.carePlanDataSub
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: IMedicalInfoDetailed[]) => {
        this.carePlans = data;
      });

    this.carePlanService.getData();
  }
}
