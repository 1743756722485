<form
  [formGroup]="identifiersForm"
  (keydown.enter)="onEnterPress($event)"
  [ngClass]="{ submitted: formSubmitted }"
>
  <div>
    <div class="required-notes">
      <p>{{ "Note: At least one identifier is required." | translate }}</p>
    </div>

    <span>
      <div fxLayout="row" fxLayoutAlign="center center" class="label-row">
        <div
          fxLayout="col"
          fxFlex="33"
          class="add-patient-col"
          fxLayoutAlign="start center"
        >
          {{ "Patient Identifier Type" | translate }}
        </div>
        <div
          fxLayout="col"
          fxFlex="33"
          class="add-patient-col"
          fxLayoutAlign="start center"
        >
          <span
            [ngSwitch]="this.identifiersForm.get('identifierType1')?.value"
            class="w-100"
          >
            <span *ngSwitchCase="'nationalId'" class="">{{
              "National Identity Number" | translate
            }}</span>

            <span *ngSwitchCase="'passport'" class="">{{
              "Passport Number" | translate
            }}</span>

            <span *ngSwitchCase="'arc'" class="">{{
              "Alien Resident Card" | translate
            }}</span>
          </span>
        </div>
        <div
          fxLayout="col"
          fxFlex="30"
          class="add-patient-col"
          fxLayoutAlign="start center"
        >
          <span [ngSwitch]="this.identifiersForm.get('identifierType1')?.value">
            <span *ngSwitchCase="'nationalId'" class="">{{
              "National Identity Issuing Country" | translate
            }}</span>

            <span *ngSwitchCase="'passport'" class="">{{
              "Passport Issuing Country" | translate
            }}</span>
          </span>
        </div>

        <div fxLayout="col" fxFlex="3" class="add-patient-col"></div>
      </div>
      <div fxLayout="row" fxLayoutAlign="center center" class="add-patient-row">
        <div fxLayout="col" fxFlex="33" class="add-patient-col">
          <nz-select
            class="add-patient-inputZorro"
            nzShowSearch
            [nzPlaceHolder]="'Patient Identifier Type' | translate"
            formControlName="identifierType1"
            [nzDisabled]="this.identifiersForm.get('identifierType1')?.value"
          >
            <nz-option
              nzLabel="National ID"
              nzValue="nationalId"
              [nzDisabled]="
                this.identifiersForm.get('identifierType2')?.value ==
                  'nationalId' ||
                this.identifiersForm.get('identifierType3')?.value ==
                  'nationalId'
              "
            ></nz-option>
            <nz-option
              nzLabel="Passport"
              nzValue="passport"
              [nzDisabled]="
                this.identifiersForm.get('identifierType2')?.value ==
                  'nationalId' ||
                this.identifiersForm.get('identifierType3')?.value == 'passport'
              "
            ></nz-option>
            <nz-option
              nzLabel="Alien Resident Card"
              nzValue="arc"
              [nzDisabled]="
                this.identifiersForm.get('identifierType2')?.value == 'arc' ||
                this.identifiersForm.get('identifierType3')?.value == 'arc'
              "
            ></nz-option>
          </nz-select>
        </div>
        <div fxLayout="col" fxFlex="33" class="add-patient-col">
          <span
            [ngSwitch]="this.identifiersForm.get('identifierType1')?.value"
            class="w-100"
          >
            <input
              *ngSwitchCase="'nationalId'"
              class="add-patient-input"
              [placeholder]="'National Identity Number' | translate"
              formControlName="nationalId"
            />

            <input
              *ngSwitchCase="'passport'"
              class="add-patient-input"
              [placeholder]="'Passport Number' | translate"
              formControlName="passport"
            />

            <input
              *ngSwitchCase="'arc'"
              class="add-patient-input"
              [placeholder]="'Alien Resident Card' | translate"
              formControlName="arc"
            />
          </span>
        </div>
        <div fxLayout="col" fxFlex="30" class="add-patient-col">
          <div
            [ngSwitch]="this.identifiersForm.get('identifierType1')?.value"
            class="w-100"
          >
            <span *ngSwitchCase="'nationalId'" class="w-100">
              <nz-select
                class="add-patient-inputZorro"
                nzShowSearch
                nzAllowClear
                [nzPlaceHolder]="
                  'National Identity Issuing Country' | translate
                "
                formControlName="nationalIdCountry"
              >
                <ng-container *ngFor="let country of countriesList">
                  <nz-option
                    nzLabel="{{ country.name }}"
                    nzValue="{{ country.id }}"
                  ></nz-option>
                </ng-container>
              </nz-select>
            </span>

            <span *ngSwitchCase="'passport'" class="w-100">
              <nz-select
                class="add-patient-inputZorro"
                nzShowSearch
                nzAllowClear
                [nzPlaceHolder]="'Passport Issuing Country' | translate"
                formControlName="passportCountry"
              >
                <ng-container *ngFor="let country of countriesList">
                  <nz-option
                    nzLabel="{{ country.name }}"
                    nzValue="{{ country.id }}"
                  ></nz-option>
                </ng-container>
              </nz-select>
            </span>
          </div>
        </div>

        <div fxLayout="col" fxFlex="3" class="add-patient-col">
          <i
            nz-icon
            *ngIf="idCount == 1"
            nzType="icons:delete"
            nzTheme="outline"
            class="delete-btn"
            (click)="removeIdentifier(1)"
          ></i>
        </div>
      </div>
    </span>

    <span *ngIf="idCount > 1">
      <div fxLayout="row" fxLayoutAlign="center center" class="label-row">
        <div
          fxLayout="col"
          fxFlex="33"
          class="add-patient-col"
          fxLayoutAlign="start center"
        >
          {{ "Patient Identifier Type" | translate }}
        </div>
        <div
          fxLayout="col"
          fxFlex="33"
          class="add-patient-col"
          fxLayoutAlign="start center"
        >
          <span
            [ngSwitch]="this.identifiersForm.get('identifierType2')?.value"
            class="w-100"
          >
            <span *ngSwitchCase="'nationalId'" class="">{{
              "National Identity Number" | translate
            }}</span>

            <span *ngSwitchCase="'passport'" class="">{{
              "Passport Number" | translate
            }}</span>

            <span *ngSwitchCase="'arc'" class="">{{
              "Alien Resident Card" | translate
            }}</span>
          </span>
        </div>

        <div
          fxLayout="col"
          fxFlex="30"
          class="add-patient-col"
          fxLayoutAlign="start center"
        >
          <span [ngSwitch]="this.identifiersForm.get('identifierType2')?.value">
            <span *ngSwitchCase="'nationalId'" class="">{{
              "National Identity Issuing Country" | translate
            }}</span>

            <span *ngSwitchCase="'passport'" class="">{{
              "Passport Issuing Country" | translate
            }}</span>
          </span>
        </div>

        <div fxLayout="col" fxFlex="3" class="add-patient-col"></div>
      </div>

      <div fxLayout="row" fxLayoutAlign="center center" class="add-patient-row">
        <div fxLayout="col" fxFlex="33" class="add-patient-col">
          <nz-select
            class="add-patient-inputZorro"
            nzShowSearch
            [nzPlaceHolder]="'Patient Identifier Type' | translate"
            formControlName="identifierType2"
            [nzDisabled]="this.identifiersForm.get('identifierType2')?.value"
          >
            <nz-option
              nzLabel="National ID"
              nzValue="nationalId"
              [nzDisabled]="
                this.identifiersForm.get('identifierType1')?.value ==
                  'nationalId' ||
                this.identifiersForm.get('identifierType3')?.value ==
                  'nationalId'
              "
            ></nz-option>
            <nz-option
              nzLabel="Passport"
              nzValue="passport"
              [nzDisabled]="
                this.identifiersForm.get('identifierType1')?.value ==
                  'passport' ||
                this.identifiersForm.get('identifierType3')?.value == 'passport'
              "
            ></nz-option>
            <nz-option
              nzLabel="Alien Resident Card"
              nzValue="arc"
              [nzDisabled]="
                this.identifiersForm.get('identifierType1')?.value == 'arc' ||
                this.identifiersForm.get('identifierType3')?.value == 'arc'
              "
            ></nz-option>
          </nz-select>
        </div>
        <div fxLayout="col" fxFlex="33" class="add-patient-col">
          <span
            [ngSwitch]="this.identifiersForm.get('identifierType2')?.value"
            class="w-100"
          >
            <input
              *ngSwitchCase="'nationalId'"
              class="add-patient-input"
              [placeholder]="'National Identity Number' | translate"
              formControlName="nationalId"
            />

            <input
              *ngSwitchCase="'passport'"
              class="add-patient-input"
              [placeholder]="'Passport Number' | translate"
              formControlName="passport"
            />

            <input
              *ngSwitchCase="'arc'"
              class="add-patient-input"
              [placeholder]="'Alien Resident Card' | translate"
              formControlName="arc"
            />
          </span>
        </div>
        <div fxLayout="col" fxFlex="30" class="add-patient-col">
          <div
            [ngSwitch]="this.identifiersForm.get('identifierType2')?.value"
            class="w-100"
          >
            <span *ngSwitchCase="'nationalId'" class="w-100">
              <nz-select
                class="add-patient-inputZorro"
                nzShowSearch
                nzAllowClear
                [nzPlaceHolder]="
                  'National Identity Issuing Country' | translate
                "
                formControlName="nationalIdCountry"
              >
                <ng-container *ngFor="let country of countriesList">
                  <nz-option
                    nzLabel="{{ country.name }}"
                    nzValue="{{ country.id }}"
                  ></nz-option>
                </ng-container>
              </nz-select>
            </span>

            <span *ngSwitchCase="'passport'" class="w-100">
              <nz-select
                class="add-patient-inputZorro"
                nzShowSearch
                nzAllowClear
                [nzPlaceHolder]="'Passport Issuing Country' | translate"
                formControlName="passportCountry"
              >
                <ng-container *ngFor="let country of countriesList">
                  <nz-option
                    nzLabel="{{ country.name }}"
                    nzValue="{{ country.id }}"
                  ></nz-option>
                </ng-container>
              </nz-select>
            </span>
          </div>
        </div>

        <div fxLayout="col" fxFlex="3" class="add-patient-col">
          <i
            nz-icon
            *ngIf="idCount == 2"
            nzType="icons:delete"
            nzTheme="outline"
            class="delete-btn"
            (click)="removeIdentifier(2)"
          ></i>
        </div>
      </div>
    </span>

    <span *ngIf="idCount > 2">
      <div fxLayout="row" fxLayoutAlign="center center" class="label-row">
        <div
          fxLayout="col"
          fxFlex="33"
          class="add-patient-col"
          fxLayoutAlign="start center"
        >
          {{ "Patient Identifier Type" | translate }}
        </div>
        <div
          fxLayout="col"
          fxFlex="33"
          class="add-patient-col"
          fxLayoutAlign="start center"
        >
          <span
            [ngSwitch]="this.identifiersForm.get('identifierType3')?.value"
            class="w-100"
          >
            <span *ngSwitchCase="'nationalId'" class="">{{
              "National Identity Number" | translate
            }}</span>

            <span *ngSwitchCase="'passport'" class="">{{
              "Passport Number" | translate
            }}</span>

            <span *ngSwitchCase="'arc'" class="">{{
              "Alien Resident Card" | translate
            }}</span>
          </span>
        </div>
        <div
          fxLayout="col"
          fxFlex="30"
          class="add-patient-col"
          fxLayoutAlign="start center"
        >
          <span [ngSwitch]="this.identifiersForm.get('identifierType3')?.value">
            <span *ngSwitchCase="'nationalId'" class="">{{
              "National Identity Issuing Country" | translate
            }}</span>

            <span *ngSwitchCase="'passport'" class="">{{
              "Passport Issuing Country" | translate
            }}</span>
          </span>
        </div>

        <div fxLayout="col" fxFlex="3" class="add-patient-col"></div>
      </div>

      <div fxLayout="row" fxLayoutAlign="center center" class="add-patient-row">
        <div fxLayout="col" fxFlex="33" class="add-patient-col">
          <nz-select
            class="add-patient-inputZorro"
            nzShowSearch
            [nzPlaceHolder]="'Patient Identifier Type' | translate"
            formControlName="identifierType3"
            [nzDisabled]="this.identifiersForm.get('identifierType3')?.value"
          >
            <nz-option
              nzLabel="National ID"
              nzValue="nationalId"
              [nzDisabled]="
                this.identifiersForm.get('identifierType1')?.value ==
                  'nationalId' ||
                this.identifiersForm.get('identifierType2')?.value ==
                  'nationalId'
              "
            ></nz-option>
            <nz-option
              nzLabel="Passport"
              nzValue="passport"
              [nzDisabled]="
                this.identifiersForm.get('identifierType1')?.value ==
                  'passport' ||
                this.identifiersForm.get('identifierType2')?.value == 'passport'
              "
            ></nz-option>
            <nz-option
              nzLabel="Alien Resident Card"
              nzValue="arc"
              [nzDisabled]="
                this.identifiersForm.get('identifierType1')?.value == 'arc' ||
                this.identifiersForm.get('identifierType2')?.value == 'arc'
              "
            ></nz-option>
          </nz-select>
        </div>
        <div fxLayout="col" fxFlex="33" class="add-patient-col">
          <span
            [ngSwitch]="this.identifiersForm.get('identifierType3')?.value"
            class="w-100"
          >
            <input
              *ngSwitchCase="'nationalId'"
              class="add-patient-input"
              [placeholder]="'National Identity Number' | translate"
              formControlName="nationalId"
            />

            <input
              *ngSwitchCase="'passport'"
              class="add-patient-input"
              [placeholder]="'Passport Number' | translate"
              formControlName="passport"
            />

            <input
              *ngSwitchCase="'arc'"
              class="add-patient-input"
              [placeholder]="'Alien Resident Card' | translate"
              formControlName="arc"
            />
          </span>
        </div>
        <div fxLayout="col" fxFlex="33" class="add-patient-col">
          <div
            [ngSwitch]="this.identifiersForm.get('identifierType3')?.value"
            class="w-100"
          >
            <span *ngSwitchCase="'nationalId'" class="w-100">
              <nz-select
                class="add-patient-inputZorro"
                nzShowSearch
                nzAllowClear
                [nzPlaceHolder]="
                  'National Identity Issuing Country' | translate
                "
                formControlName="nationalIdCountry"
              >
                <ng-container *ngFor="let country of countriesList">
                  <nz-option
                    nzLabel="{{ country.name }}"
                    nzValue="{{ country.id }}"
                  ></nz-option>
                </ng-container>
              </nz-select>
            </span>

            <span *ngSwitchCase="'passport'" class="w-100">
              <nz-select
                class="add-patient-inputZorro"
                nzShowSearch
                nzAllowClear
                [nzPlaceHolder]="'Passport Issuing Country' | translate"
                formControlName="passportCountry"
              >
                <ng-container *ngFor="let country of countriesList">
                  <nz-option
                    nzLabel="{{ country.name }}"
                    nzValue="{{ country.id }}"
                  ></nz-option>
                </ng-container>
              </nz-select>
            </span>
          </div>
        </div>

        <div fxLayout="col" fxFlex="3" class="add-patient-col">
          <i
            nz-icon
            *ngIf="idCount == 3"
            nzType="icons:delete"
            nzTheme="outline"
            class="delete-btn"
            (click)="removeIdentifier(3)"
          ></i>
        </div>
      </div>
    </span>

    <div fxLayout="row" class="mb-1" *ngIf="idCount < 3">
      <div fxLayout="column" class="add-patient-col" fxFlex="50">
        <div fxLayout="row">
          <button nz-button (click)="addID()" class="add-another-id">
            {{ "Add Another Identifier" | translate }}
          </button>
        </div>
      </div>
    </div>
    <div fxLayout="row" style="height: 70px">
      <div fxLayout="column" class="add-patient-col" fxFlex="50">
        <div fxLayout="row">
          <span>{{ "Email Address" | translate }}</span>
        </div>
        <div fxLayout="row" class="">
          <input
            class="add-patient-input"
            [placeholder]="'Email Address' | translate"
            formControlName="email"
          />
        </div>
      </div>
    </div>
  </div>
</form>

<!-- <form [formGroup]="identifiersForm" [ngClass]="{ submitted: formSubmitted }">
  <div>
    <div class="required-notes">
      <p>{{ "Note: At least one identifier is required." | translate }}</p>
    </div>

    <ng-container
      *ngFor="let idn of (identifiersForm.get('identifierType')?.value.length > 0 ? identifiersForm.get('identifierType')?.value : [{}]); let i = index">
      <div>

        <div fxLayout="row" fxLayoutAlign="center center" class="label-row">
          <div fxLayout="col" fxFlex="33" class="add-patient-col" fxLayoutAlign="start center">
            <span *ngIf="i==0">{{ "Patient Identifier Type" | translate }}</span>
          </div>

          <div fxLayout="col" fxFlex="33" class="add-patient-col" fxLayoutAlign="start center">
            <span *ngIf="this.identifiersForm.get('identifierType')?.value?.at(i)=='nationalId'">{{ "National
              Identity Number" | translate }}</span>

            <span *ngIf="this.identifiersForm.get('identifierType')?.value?.at(i)=='passport'">{{ "Passport Number"
              |
              translate
              }}</span>

            <span *ngIf="this.identifiersForm.get('identifierType')?.value?.at(i)=='arc'">{{ "Alien Resident Card"
              |
              translate }}</span>

          </div>

          <div fxLayout="col" fxFlex="33" class="add-patient-col" fxLayoutAlign="start center">
            <span *ngIf="this.identifiersForm.get('identifierType')?.value?.at(i)=='nationalId'">{{ "National
              Identity
              Issuing Country" | translate }}</span>

            <span *ngIf="this.identifiersForm.get('identifierType')?.value?.at(i)=='passport'">{{"Passport Issuing
              Country" | translate }}</span>


            <span *ngIf="this.identifiersForm.get('identifierType')?.value?.at(i)=='arc'"></span>
          </div>

        </div>
        <div fxLayout="row" fxLayoutAlign="center center" class="add-patient-row" fxLayoutGap="5px">
          <div fxLayout="col" fxFlex="33" class="add-patient-col">
            <nz-select class="add-patient-inputZorro" nzShowSearch
              [nzPlaceHolder]="'Patient Identifier Type' | translate" formControlName="identifierType" *ngIf="i==0"
              nzMode="multiple">
              <nz-option nzLabel="National ID" nzValue="nationalId"
                [nzDisabled]="this.identifiersForm.get('identifierType')?.value.includes('nationalId')"></nz-option>
              <nz-option nzLabel="Passport" nzValue="passport"
                [nzDisabled]="this.identifiersForm.get('identifierType')?.value.includes('passport')"></nz-option>
              <nz-option nzLabel="Alien Resident Card" nzValue="arc"
                [nzDisabled]="this.identifiersForm.get('identifierType')?.value?.includes('arc')"></nz-option>
            </nz-select>
          </div>

          <div fxLayout="col" fxFlex="33" class="add-patient-col">
            <input class="add-patient-input" [placeholder]="'National Identity Number' | translate"
              formControlName="nationalId"
              *ngIf="this.identifiersForm.get('identifierType')?.value?.at(i)=='nationalId'" />

            <input class="add-patient-input" [placeholder]="'Passport Number' | translate" formControlName="passport"
              *ngIf="this.identifiersForm.get('identifierType')?.value?.at(i)=='passport'" />

            <input class="add-patient-input" [placeholder]="'Alien Resident Card' | translate" formControlName="arc"
              *ngIf="this.identifiersForm.get('identifierType')?.value?.at(i)=='arc'" />
          </div>

          <div fxLayout="col" fxFlex="33" class="add-patient-col">
            <nz-select class="add-patient-inputZorro" nzShowSearch nzAllowClear
              [nzPlaceHolder]="'National Identity Issuing Country' | translate" formControlName="nationalIdCountry"
              *ngIf="this.identifiersForm.get('identifierType')?.value?.at(i)=='nationalId'">
              <ng-container *ngFor="let country of countriesList">
                <nz-option nzLabel="{{ country.name }}" nzValue="{{ country.id }}"></nz-option>
              </ng-container>
            </nz-select>

            <nz-select class="add-patient-inputZorro" nzShowSearch nzAllowClear
              [nzPlaceHolder]="'Passport Issuing Country' | translate" formControlName="passportCountry"
              *ngIf="this.identifiersForm.get('identifierType')?.value?.at(i)=='passport'">
              <ng-container *ngFor="let country of countriesList">
                <nz-option nzLabel="{{ country.name }}" nzValue="{{ country.id }}"></nz-option>
              </ng-container>
            </nz-select>

            <span *ngIf="this.identifiersForm.get('identifierType')?.value=='arc'"></span>
          </div>

        </div>

      </div>


    </ng-container>
  </div>
  <div fxLayout="row" style="height:70px">
    <div fxLayout="column" class="add-patient-col" fxFlex="50">
      <div fxLayout="row">
        <span>{{ "Email Address" | translate }}</span>
      </div>
      <div fxLayout="row" class="">
        <input class="add-patient-input" [placeholder]="'Email Address' | translate" formControlName="email" />
      </div>
    </div>
  </div>
</form> -->
