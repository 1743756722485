import { Component, ViewChild } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { NzModalModule, NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NzSelectModule, NzSelectOptionInterface } from 'ng-zorro-antd/select';
import { CommonModule } from '@angular/common';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { TooltipComponent } from '../../../../../../../common/tooltip/tooltip.component';
import { NzIconModule } from 'ng-zorro-antd/icon';
import Swal from 'sweetalert2';
import { Subject, takeUntil } from 'rxjs';
import { CurrentProblemsService } from '../../../../../../../services/currentProblems.service';
import { IProblemDropdown } from '../../../../../../../interfaces/Problems/IProblemDropdown';
import { IAddProblem } from '../../../../../../../interfaces/Problems/IAddProblem';
import { ICoding } from 'src/app/interfaces/ICoding';
import { NzNoAnimationModule } from 'ng-zorro-antd/core/no-animation';

@Component({
  standalone: true,
  selector: 'app-current-problems-add-edit',
  templateUrl: './current-problems-add-edit.component.html',
  styleUrls: ['./current-problems-add-edit.component.css'],
  imports: [
    NzModalModule,
    TranslateModule,
    FormsModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    NzSelectModule,
    CommonModule,
    NzButtonModule,
    NzTableModule,
    NzLayoutModule,
    TooltipComponent,
    NzIconModule,
    NzNoAnimationModule,
  ],
})
export class CurrentProblemsAddEditComponent {
  private destroy$ = new Subject<void>();

  problemsDropdown: IProblemDropdown | undefined;
  idDropdown: NzSelectOptionInterface[] = [];
  rdDropdown: NzSelectOptionInterface[] = [];

  isVisible = false;
  isLoading = false;
  isIdLoading = false;
  isRdLoading = false;

  isEdit = false;
  editProblemsId?: number;

  problemsSubmitted = false;

  @ViewChild('modalRef', { static: true }) modalRef!: NzModalRef; // Reference to the modal

  problemsForm: FormGroup = new FormGroup({});

  constructor(
    private readonly currentProblemsService: CurrentProblemsService,
    private readonly formBuilder: FormBuilder
  ) {}

  private idValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const tvalue = control?.parent?.get('typeValue')?.value;
      const ivalue = control?.parent?.get('illnessDisorderValue')?.value;

      if (tvalue == 0) {
        if (!ivalue || ivalue == '' || ivalue == null) {
          this.problemsForm.get('illnessDisorderValue')?.setErrors({
            illnessDisorderValue: true,
          });

          return { illnessDisorderValue: true };
        }
      }

      this.problemsForm.get('illnessDisorderValue')?.setErrors(null);
      this.problemsForm.get('rareDiseaseValue')?.setErrors(null);

      return null;
    };
  }

  private rdValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const tvalue = control?.parent?.get('typeValue')?.value;
      const rvalue = control?.parent?.get('rareDiseaseValue')?.value;

      if (tvalue == 1) {
        if (!rvalue || rvalue == '' || rvalue == null) {
          this.problemsForm.get('rareDiseaseValue')?.setErrors({
            rareDiseaseValue: true,
          });

          return { rareDiseaseValue: true };
        }
      }

      this.problemsForm.get('illnessDisorderValue')?.setErrors(null);
      this.problemsForm.get('rareDiseaseValue')?.setErrors(null);

      return null;
    };
  }

  ngOnInit() {
    this.isLoading = true;
    this.isIdLoading = true;
    this.isRdLoading = true;
    this.isEdit = false;

    this.currentProblemsService.getProblemDropdown().subscribe({
      next: (pro) => {
        this.problemsDropdown = pro?.data;
        this.isLoading = false;
      },
      error: (err) => {
        console.log(err);
      },
    });

    this.currentProblemsService.getIllnessDisorderDropdown().subscribe({
      next: (pro) => {
        this.idDropdown = pro?.data?.illnessandDisorder.map((d) => {
          return {
            label: d.name,
            value: d.id,
          };
        });

        this.isIdLoading = false;
      },
      error: (err) => {
        console.log(err);
      },
    });

    this.currentProblemsService.getRareDiseaseDropdown().subscribe({
      next: (pro) => {
        this.rdDropdown = pro?.data?.rareDisease.map((d) => {
          return {
            label: d.name,
            value: d.id,
          };
        });

        this.isRdLoading = false;
      },
      error: (err) => {
        console.log(err);
      },
    });

    this.problemsForm = this.formBuilder.group({
      problemValue: [null, Validators.required],
      typeValue: [null, Validators.required],
      statusCodeValue: [null, Validators.required],
      diagnosisAssertionValue: [null, Validators.required],
      onSetDateValue: [null, Validators.required],
      descriptionValue: [null, Validators.required],
      resolutionCircumstancesTextValue: [null, Validators.required],
      rareDiseaseValue: [null, this.rdValidator()],
      illnessDisorderValue: [null, this.idValidator()],
    });

    this.currentProblemsService.currentProblemEditSub
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        // GET DATA FIELDS AND ASSIGN DEFAULT VALUES TO FORM
        if (data) {
          this.isEdit = true;
          this.editProblemsId = data?.id;

          this.problemsForm.patchValue({
            problemValue: data?.problemId,
            typeValue: data?.illnessAndDisorderId ? 0 : 1,
            statusCodeValue: data?.statusCodeId,
            diagnosisAssertionValue: data?.diagnosisAssertionId,
            onSetDateValue: data?.onSetDate,
            resolutionCircumstancesTextValue: data?.resolutionCircumstancesText,
            descriptionValue: data?.description,
            rareDiseaseValue: data?.rareDiseaseId,
            illnessDisorderValue: data?.illnessAndDisorderId,
          });
        } else {
          this.isEdit = false;
          this.editProblemsId = undefined;

          this?.problemsForm.reset();
        }
      });

    this.currentProblemsService.currentProblemModalSub
      .pipe(takeUntil(this.destroy$))
      .subscribe((showModal) => {
        this.isVisible = showModal;
      });
  }

  ngOnDestroy() {
    // Unsubscribe from observables
    this.destroy$.next();
    this.destroy$.complete();

    this?.modalRef.destroy();
  }

  getCurrentDate() {
    return new Date().toISOString().split('T')[0];
  }

  handleCancel() {
    this.problemsForm.reset();

    this.currentProblemsService.currentProblemModalSub.next(false);

    this.problemsSubmitted = false;

    this.isLoading = false;

    this.modalRef.destroy();
  }

  submitForm() {
    this.isLoading = true;
    this.problemsSubmitted = true;

    if (this.problemsForm.valid) {
      const problemObj: IAddProblem = {};

      problemObj.id = this.editProblemsId;
      problemObj.problemId = this.problemsForm.value?.problemValue;
      problemObj.statusCodeId = this.problemsForm.value?.statusCodeValue;
      problemObj.diagnosisAssertionId =
        this.problemsForm.value?.diagnosisAssertionValue;
      problemObj.onSetDate = this.problemsForm.value?.onSetDateValue;
      problemObj.resolutionCircumstancesText =
        this.problemsForm.value?.resolutionCircumstancesTextValue;
      problemObj.description = this.problemsForm.value?.descriptionValue;

      if (this.problemsForm.value?.typeValue == 0) {
        problemObj.illnessAndDisorderId =
          this.problemsForm.value?.illnessDisorderValue;
      }

      if (this.problemsForm.value?.typeValue == 1) {
        problemObj.rareDiseaseId = this.problemsForm.value?.rareDiseaseValue;
      }

      if (this.isEdit) {
        this.currentProblemsService.editProblem(problemObj).subscribe({
          next: () => {
            Swal.fire({
              text: 'Current problem edited successfully!',
              toast: true,
              position: 'bottom-end',
              showCancelButton: false,
              showConfirmButton: false,
              color: 'white',
              background: '#0d9488',
              timer: 3000,
            });

            this.currentProblemsService.getData();

            this.handleCancel();
          },
          error: (err) => {
            Swal.fire({
              text: 'Unable to edit current problem!',
              toast: true,
              position: 'bottom-end',
              showCancelButton: false,
              showConfirmButton: false,
              color: 'white',
              background: '#ff6969',
              timer: 3000,
            });
            console.log(err);

            this.handleCancel();
          },
        });
      } else {
        this.currentProblemsService.addProblem(problemObj).subscribe({
          next: () => {
            Swal.fire({
              text: 'Current problem added successfully!',
              toast: true,
              position: 'bottom-end',
              showCancelButton: false,
              showConfirmButton: false,
              color: 'white',
              background: '#0d9488',
              timer: 3000,
            });

            this.currentProblemsService.getData();

            this.handleCancel();
          },
          error: (err) => {
            Swal.fire({
              text: 'Unable to add current problem!',
              toast: true,
              position: 'bottom-end',
              showCancelButton: false,
              showConfirmButton: false,
              color: 'white',
              background: '#ff6969',
              timer: 3000,
            });
            console.log(err);

            this.handleCancel();
          },
        });
      }
    } else {
      this.isLoading = false;
    }
  }
}
