<form [formGroup]="addressInfoForm" [ngClass]="{ submitted: formSubmitted }">
  <div class="layout" fxLayout="column" fxLayoutAlign="center center" style="height: 100%">

    <!-- First Row -->
    <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutAlign="start center" class="label-row large-screen w-100">
      <div fxLayout="column" fxFlex="60" class="add-patient-col w-100" fxLayoutAlign="center start">
        <div>{{ "Street" | translate }}
          <span class="optional">{{ "(optional)" | translate }}</span>
        </div>
        <input class="add-patient-input" [placeholder]="'Street' | translate" formControlName="addressStreet" />
      </div>

      <div fxLayout="column" fxFlex="20" class="add-patient-col w-100" fxLayoutAlign="center start">
        <div> {{ "Street Number" | translate }}
          <span class="optional">{{ "(optional)" | translate }}</span>
        </div>
        <input class="add-patient-input" [placeholder]="'Street Number' | translate"
          formControlName="addressStreetNumber" />
      </div>

      <div fxLayout="column" fxFlex="20" class="add-patient-col w-100" fxLayoutAlign="center start">
        <div> {{ "Apartment Number" | translate}}
          <span class="optional">{{ "(optional)" | translate }}</span>
        </div>
        <input class="add-patient-input" [placeholder]="'Apartment Number' | translate"
          formControlName="addressApartmentNumber" />
      </div>

    </div>

    <!-- Second Row -->
    <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutAlign="center center" class="label-row large-screen w-100">
      <div fxLayout="column" fxFlex="25" class="add-patient-col w-100" fxLayoutAlign="center start">
        <div>{{ "Postcode" | translate }}
          <span class="optional">{{ "(optional)" | translate }}</span>
        </div>
        <input class="add-patient-input" [placeholder]="'Postcode' | translate" formControlName="addressPostCode" />
      </div>

      <div fxLayout="column" fxFlex="25" class="add-patient-col w-100" fxLayoutAlign="center start">
        <div>
          {{ "District" | translate }}
          <span class="optional">{{ "(optional)" | translate }}</span>
        </div>
        <ng-container *ngIf="!isCountryCyprus; else cyprusDropdown">
          <input class="add-patient-input" [placeholder]="'District' | translate" formControlName="addressDistrict" />
        </ng-container>
        <!-- Dropdown if Cyprus -->
        <ng-template #cyprusDropdown>
          <nz-select class="add-patient-inputZorro" nzShowSearch nzAllowClear
            [nzPlaceHolder]="'Select a District' | translate" formControlName="addressDistrict">
            <ng-container *ngFor="let city of cyprusCities">
              <nz-option [nzLabel]="city | translate" [nzValue]="city"></nz-option>
            </ng-container>
          </nz-select>
        </ng-template>
      </div>

      <div fxLayout="column" fxFlex="25" class="add-patient-col w-100" fxLayoutAlign="center start">
        <div>
          {{ "Town" | translate }}
          <span class="optional">{{ "(optional)" | translate }}</span>
        </div>
        <input class="add-patient-input" [placeholder]="'Town' | translate" formControlName="addressTown" />
      </div>

      <div fxLayout="column" fxFlex="25" class="add-patient-col w-100" fxLayoutAlign="center start">
        <div> {{ "Country" | translate }}
          <span class="optional">{{ "(optional)" | translate }}</span>
        </div>
        <nz-select class="add-patient-inputZorro" nzShowSearch nzAllowClear [nzPlaceHolder]="'Country' | translate"
          formControlName="addressCountryId" (ngModelChange)="onCountryChange($event)">
          <ng-container *ngFor="let place of countriesList">
            <nz-option [nzLabel]="place.name" [nzValue]="place.id"></nz-option>
          </ng-container>
        </nz-select>
      </div>
    </div>

  </div>
</form>