import { Component } from '@angular/core';
import { Model } from 'survey-core';
import { json } from './json';

@Component({
  selector: 'app-cognitive-assessment-section',
  templateUrl: './cognitive-assessment-section.component.html',
  styleUrl: './cognitive-assessment-section.component.css',
})
export class CognitiveAssessmentSectionComponent {
  model: Model = new Model();

  ngOnInit() {
    const survey = new Model(json);
    survey.onComplete.add((sender, options) => {
      console.log(JSON.stringify(sender.data, null, 3));
    });

    this.model = survey;
  }
}
