<form [formGroup]="rejectForm" (ngSubmit)="submitForm()" [ngClass]="{ 'reject-submitted': formSubmitted }"
  style="padding: 20px !important">
  <div style="
      margin-top: 20px !important;
      display: flex !important;
      align-items: center !important;
    ">
    <div style="
        flex: 0 0 20% !important;
        display: flex !important;
        align-items: center !important;
        padding-right: 20px !important;
      ">
      <label>{{ "Reject Reason" | translate }}</label>
    </div>
    <div style="
        flex: 1 !important;
        display: flex !important;
        align-items: center !important;
        width: 100% !important;
      ">
      <nz-select nzShowSearch nzAllowClear [nzPlaceHolder]="'Reject Reason' | translate" formControlName="rejectReason"
        style="width: 100% !important; margin-left: 1em !important">
        <ng-container *ngFor="let reason of rejectReasonsList">
          <nz-option [nzLabel]="reason.name" [nzValue]="reason.id"></nz-option>
        </ng-container>
      </nz-select>
    </div>
  </div>

  <div style="
      margin-top: 20px !important;
      display: flex !important;
      align-items: center !important;
    ">
    <div style="
        flex: 0 0 20% !important;
        display: flex !important;
        align-items: center !important;
        padding-right: 20px !important;
      ">
      <label>{{ "Notes" | translate
        }}<span style="
            font-weight: 300 !important;
            margin-left: 0.5em !important;
            font-size: 12px !important;
          ">{{ "(optional)" | translate }}</span></label>
    </div>
    <div style="
        flex: 1 !important;
        display: flex !important;
        align-items: center !important;
        width: 100% !important;
      ">
      <textarea rows="4" nz-input style="
          width: calc(100% - 1em) !important;
          margin-left: 1em !important;
          border: 1px solid #d9d9d9 !important;
          padding-left: 11px !important;
          padding-right: 11px !important;
          padding-top: 6px !important;
          padding-bottom: 6px !important;
          border-radius: 4px !important;
        " placeholder="{{ 'Notes' | translate }}" formControlName="notes"></textarea>
    </div>
  </div>

  <div style="
      margin-top: 20px !important;
      display: flex !important;
      justify-content: flex-end !important;
    ">
    <button nz-button nzPrimary type="submit" [nzLoading]="isLoading" [disabled]="isLoading"
      style="margin-right: 1em !important">
      {{ "Save" | translate }}
    </button>
    <button nz-button nzDanger type="button" (click)="cancel()" [disabled]="isLoading"
      style="background: #f3f3f3 !important; border: 1px solid gray !important">
      {{ "Cancel" | translate }}
    </button>
  </div>
</form>