import { Component, ViewChild } from '@angular/core';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { Subject, takeUntil } from 'rxjs';
import { ImagingService } from 'src/app/services/imaging.service';
import { IAddImaging } from 'src/app/interfaces/Imaging/IAddImaging';
import { IImagingDropdown } from 'src/app/interfaces/Imaging/IImagingDropdown';

@Component({
  selector: 'app-imaging-files-add',
  templateUrl: './imaging-files-add.component.html',
  styleUrls: ['./imaging-files-add.component.css'],
})
export class ImagingFilesAddComponent {
  private destroy$ = new Subject<void>();

  imagingDropdown: IImagingDropdown | undefined;

  fileObj?: FileList;

  isVisible = false;
  isLoading = false;

  isEdit = false;
  editProblemsId?: number;

  imagingSubmitted = false;

  @ViewChild('modalRef', { static: true }) modalRef!: NzModalRef; // Reference to the modal

  imagingForm: FormGroup = new FormGroup({});

  constructor(
    private readonly imagingService: ImagingService,
    private readonly formBuilder: FormBuilder
  ) {}

  ngOnInit() {
    this.isLoading = true;
    this.isEdit = false;

    this.imagingService.getImagingDropdown().subscribe({
      next: (pro) => {
        this.imagingDropdown = pro?.data;
        this.isLoading = false;
      },
      error: (err) => {
        console.log(err);
      },
    });

    this.imagingForm = this.formBuilder.group({
      categoryValue: [null, Validators.required],
      modalityValue: [null, Validators.required],
      examTitleValue: [null, Validators.required],
      dateValue: [null, Validators.required],
      descriptionValue: [null, Validators.required],
      fileValue: [[], Validators.required],
      reportValue: [null, Validators.required],
    });

    this.imagingService.imagingEditSub
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        // GET DATA FIELDS AND ASSIGN DEFAULT VALUES TO FORM
        if (data) {
          this.isEdit = true;
          this.editProblemsId = data?.id;

          this.imagingForm.patchValue({
            categoryValue: data?.categoryId,
            modalityValue: data?.modalityId,
            examTitleValue: data?.examTitle,
            dateValue: data?.date,
            descriptionValue: data?.description,
          });
        } else {
          this.isEdit = false;
          this.editProblemsId = undefined;

          this?.imagingForm.reset();
        }
      });

    this.imagingService.imagingModalSub
      .pipe(takeUntil(this.destroy$))
      .subscribe((showModal) => {
        this.isVisible = showModal;
      });
  }

  ngOnDestroy() {
    // Unsubscribe from observables
    this.destroy$.next();
    this.destroy$.complete();

    this?.modalRef.destroy();
  }

  getCurrentDate() {
    return new Date().toISOString().split('T')[0];
  }

  handleCancel() {
    this.imagingForm.reset();

    this.imagingService.imagingModalSub.next(false);

    this.imagingSubmitted = false;

    this.isLoading = false;

    this.modalRef.destroy();
  }

  submitForm() {
    this.isLoading = true;
    this.imagingSubmitted = true;

    if (this.fileObj && this.imagingForm.valid) {
      const imagingObj: IAddImaging = {};

      imagingObj.id = this.editProblemsId;
      imagingObj.categoryId = this.imagingForm.value?.categoryValue;
      imagingObj.modalityId = this.imagingForm.value?.modalityValue;
      imagingObj.examTitle = this.imagingForm.value?.examTitleValue;
      imagingObj.date = this.imagingForm.value?.dateValue;
      imagingObj.description = this.imagingForm.value?.descriptionValue;
      imagingObj.files = Array.from(this.fileObj);
      imagingObj.reportFile = this.imagingForm.value?.reportValue;

      if (this.isEdit) {
        this.imagingService.editImaging(imagingObj).subscribe({
          next: () => {
            Swal.fire({
              text: 'Imaging edited successfully!',
              toast: true,
              position: 'bottom-end',
              showCancelButton: false,
              showConfirmButton: false,
              color: 'white',
              background: '#0d9488',
              timer: 3000,
            });

            this.imagingService.getData();

            this.handleCancel();
          },
          error: (err) => {
            Swal.fire({
              text: 'Unable to edit Imaging!',
              toast: true,
              position: 'bottom-end',
              showCancelButton: false,
              showConfirmButton: false,
              color: 'white',
              background: '#ff6969',
              timer: 3000,
            });
            console.log(err);

            this.handleCancel();
          },
        });
      } else {
        this.imagingService.addImaging(imagingObj).subscribe({
          next: () => {
            Swal.fire({
              text: 'Imaging added successfully!',
              toast: true,
              position: 'bottom-end',
              showCancelButton: false,
              showConfirmButton: false,
              color: 'white',
              background: '#0d9488',
              timer: 3000,
            });

            this.imagingService.getData();

            this.handleCancel();
          },
          error: (err) => {
            Swal.fire({
              text: 'Unable to add Imaging!',
              toast: true,
              position: 'bottom-end',
              showCancelButton: false,
              showConfirmButton: false,
              color: 'white',
              background: '#ff6969',
              timer: 3000,
            });
            console.log(err);

            this.handleCancel();
          },
        });
      }
    } else {
      this.isLoading = false;
    }
  }

  onFileSelected(event: any) {
    const files: FileList | null = event.target.files;

    if (files) {
      this.fileObj = files;
      const existingFiles = this.imagingForm.get('fileValue')?.value || [];
      this.imagingForm.get('fileValue')?.setValue(existingFiles.push(files));
      this.imagingForm.get('fileValue')?.setErrors(null);
    } else {
      this.fileObj = undefined;
      this.imagingForm.get('fileValue')?.setValue(undefined);
      this.imagingForm?.get('fileValue')?.setErrors({ invalid: true });
    }
  }

  onReportSelected(event: any) {
    const file: File | null = event.target.files?.item(0);

    if (file) {
      this.imagingForm.get('reportValue')?.setValue(file);
      this.imagingForm.get('reportValue')?.setErrors(null);
    } else {
      this.fileObj = undefined;
      this.imagingForm.get('reportValue')?.setValue(undefined);
      this.imagingForm?.get('reportValue')?.setErrors({ invalid: true });
    }
  }

  filteredModalities() {
    return (
      this.imagingDropdown?.modalities?.filter(
        (m) => m.categoryId === this.imagingForm.get('categoryValue')?.value
      ) || []
    );
  }

  categoryChange(event: any) {
    event ? undefined : this.imagingForm.get('modalityValue')?.setValue(null);
  }

  // Disallow Dots in exam title
  // in order not to mess up with the file download
  disallowDots(event: KeyboardEvent): void {
    if (event.key === '.') {
      event.preventDefault();
    }
  }
}
