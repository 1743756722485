import { Component } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import Swal from 'sweetalert2';
import { IMedicalInfoDetailed } from '../../../../../../interfaces/MedicalInfo/IMedicalInfoDetailed';
import { MedicalHistoryService } from '../../../../../../services/medicalHistory.service';
import { MedicalHistoryAddEditComponent } from './medical-history-add-edit/medical-history-add-edit.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-medical-history',
  templateUrl: './medical-history.component.html',
  styleUrls: ['./medical-history.component.css'],
})
export class MedicalHistoryComponent {
  public addComp = MedicalHistoryAddEditComponent;

  private destroy$ = new Subject<void>();

  medicalAlerts?: IMedicalInfoDetailed[];
  isLoading: boolean = false;

  constructor(
    private readonly medicalHistoryService: MedicalHistoryService,
    private readonly translate: TranslateService
  ) {}

  openModalFun(item?: IMedicalInfoDetailed) {
    this.medicalHistoryService.medicalHistoryModalSub.next(true);
    this.medicalHistoryService.medicalHistoryEditSub.next(item);
  }

  deleteFun(medicalAlertId: number) {
    Swal.fire({
      text: `${this.translate.instant(
        'deletePlaceholder1'
      )} ${this.translate.instant('Medical History')}?`,
      showDenyButton: false,
      showCancelButton: true,
      cancelButtonText: `${this.translate.instant('Cancel')}`,
      confirmButtonText: `${this.translate.instant('Ok')}`,
    }).then((result) => {
      if (result.isConfirmed) {
        this.medicalHistoryService
          .deleteMedicalHistory(medicalAlertId.toString())
          .subscribe({
            next: (d) => {
              Swal.fire({
                text: 'Medical History deleted successfully!',
                toast: true,
                position: 'bottom-end',
                showCancelButton: false,
                showConfirmButton: false,
                color: 'white',
                background: '#0d9488',
                timer: 3000,
              });

              this.medicalHistoryService.deleteMedicalHistoryFromTable(
                medicalAlertId.toString()
              );
            },
            error: (err) => {
              Swal.fire({
                text: 'Unable to delete Medical History!',
                toast: true,
                position: 'bottom-end',
                showCancelButton: false,
                showConfirmButton: false,
                color: 'white',
                background: '#ff6969',
                timer: 3000,
              });
              console.log(err);
            },
          });
      } else if (result.isDenied) {
        Swal.fire('Medical history is not deleted', '', 'info');
      }
    });
  }
  ngOnInit() {
    this.medicalHistoryService.medicalHistoryLoadingSub
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: boolean) => {
        this.isLoading = data;
      });

    this.medicalHistoryService.medicalHistoryDataSub
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: IMedicalInfoDetailed[]) => {
        this.medicalAlerts = data;
      });

    this.medicalHistoryService.getData();
  }
}
