<form [formGroup]="medicalForm" [ngClass]="{ submitted: formSubmitted }">

  <!-- Assigned Doctors -->

  <div fxLayout="row" fxLayoutAlign="center center" class="label-row">
    <div fxLayout="col" fxFlex="50" class="add-patient-col " fxLayoutAlign="start center">
      {{ "Assigned Doctors" | translate }}
    </div>

    <div fxLayout="col" fxFlex="50" class="add-patient-col " fxLayoutAlign="start center">
      <span *ngIf="selectedDoctors?.length! > 0">{{ "Select Primary Doctor" | translate }}</span>
    </div>
  </div>

  <div fxLayout="row" fxLayoutAlign="center center" class="add-patient-row">
    <div fxLayout="column" fxFlex="50" class="add-patient-col h100i" fxLayoutAlign="center start">
      <nz-select class="add-patient-multi-inputZorro" nzMode="multiple" nzShowSearch nzAllowClear
        [nzPlaceHolder]="'Assigned Doctors' | translate" formControlName="assignedDoctors" [nzOptions]="doctors"
        (ngModelChange)="onDoctorSelectionChange($event)">
      </nz-select>
    </div>

    <div fxLayout="column" fxFlex="50" class="add-patient-col h100i" fxLayoutAlign="center start">
      <div fxLayout="row wrap">
        <div *ngFor="let doctor of selectedDoctors" style="margin-right:20px">
          <label class="radio-label">
            <input type="radio" name="primaryDoctor" (change)="setPrimaryDoctor(doctor.id, $event)" [value]="doctor.id"
              [checked]="doctor.primary" />
            <span>{{ doctor.label }}</span>
          </label>
        </div>
      </div>

    </div>
  </div>

  <div fxLayout="row" fxLayoutAlign="start center" class="add-patient-row">
    <input type="checkbox" class="custom-checkbox" id="mohValue" formControlName="mohValue" />
    <label for="mohValue" class="hio">{{
      "Chronic patient from MOH/DMSW" | translate
      }}</label>
  </div>

  <div fxLayout="row" fxLayoutAlign="center center" class="label-row-2">
    <div fxLayout="column" fxFlex="25" class="add-patient-col" fxLayoutAlign="center start">
      {{ " Immobility" | translate }}
      <nz-select class="add-patient-inputZorro" nzShowSearch nzAllowClear [nzPlaceHolder]="'Immobility' | translate"
        formControlName="immobilityValue" [nzOptions]="immobilityList">
      </nz-select>
    </div>
    <div fxLayout="column" fxFlex="25" class="add-patient-col" fxLayoutAlign="center start">
      <div>{{ " Blood Type" | translate }} <span class="optional">{{ "(optional)" | translate }}</span>
      </div>
      <nz-select class="add-patient-inputZorro" nzShowSearch nzAllowClear [nzPlaceHolder]="'Blood Type' | translate"
        formControlName="bloodtype">
        <ng-container *ngFor="let bt of bloodTypesList">
          <nz-option nzLabel="{{ bt.name }}" nzValue="{{ bt.id }}"></nz-option>
        </ng-container>
      </nz-select>
    </div>
    <div fxLayout="column" fxFlex="25" class="add-patient-col" fxLayoutAlign="center start">
      <div>
        {{ " Height" | translate }} <span class="optional">{{ "(optional)" | translate }}</span>
      </div>
      <input type="number" class="add-patient-input" [placeholder]="'Height' | translate" formControlName="height" />
    </div>
    <div fxLayout="column" fxFlex="25" class="add-patient-col" fxLayoutAlign="center start">
      <div>
        {{ " Weight" | translate }} <span class="optional">{{ "(optional)" | translate }}</span>
      </div>
      <input type="number" class="add-patient-input" [placeholder]="'Weight' | translate" formControlName="weight" />
    </div>
  </div>

</form>