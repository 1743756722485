import { Component, HostListener, OnInit } from '@angular/core';
import { IUserManagementNew } from '../../../../interfaces/IUserManagementNew';
import { UserManagementService } from '../../../../services/userManagement.service';
import { Router } from '@angular/router';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import Swal from 'sweetalert2';
import { MatStepper } from '@angular/material/stepper';
import { TranslateService } from '@ngx-translate/core';
import {
  CountryISO,
  PhoneNumberFormat,
  SearchCountryField,
} from 'ngx-intl-tel-input-gg';

@Component({
  selector: 'app-user-management-new',
  templateUrl: './user-management-new.component.html',
  styleUrls: ['./user-management-new.component.css'],
})
export class UserManagementNewComponent implements OnInit {
  roles: any[] = [];
  locales: any[] = [];
  loading = false;

  personalInfoForm!: FormGroup;
  contactInfoForm!: FormGroup;
  rolesAndLanguageForm!: FormGroup;

  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
  preferredCountries: CountryISO[] = [CountryISO.Cyprus, CountryISO.Greece];

  @HostListener('window:beforeunload', ['$event'])
  handleBeforeUnload(event: BeforeUnloadEvent) {
    event.preventDefault();
    event.returnValue = '';
  }

  newUser: IUserManagementNew = {
    userRegistration: {
      username: '',
      email: '',
      firstName: '',
      lastName: '',
      credentials: [{ value: '' }],
      attributes: {
        locale: '',
        phoneNumber: '',
      },
    },
    userRole: [],
  };

  constructor(
    private readonly userManagement: UserManagementService,
    private readonly router: Router,
    private formBuilder: FormBuilder,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    this.fetchDropdownData();
    this.buildForm();
  }

  buildForm() {
    this.personalInfoForm = this.formBuilder.group({
      username: [this.newUser.userRegistration.username, Validators.required],
      firstName: [this.newUser.userRegistration.firstName, Validators.required],
      lastName: [this.newUser.userRegistration.lastName, Validators.required],
      tempPassword: [
        this.newUser.userRegistration.credentials[0].value,
        Validators.required,
      ],
    });

    this.contactInfoForm = this.formBuilder.group({
      email: [
        this.newUser.userRegistration.email,
        [Validators.required, Validators.email],
      ],
      phoneNumber: [undefined, Validators.required],
    });

    this.rolesAndLanguageForm = this.formBuilder.group({
      roles: [this.newUser.userRole, Validators.required],
      locale: [
        this.newUser.userRegistration.attributes.locale,
        Validators.required,
      ],
    });
  }

  //NEEDS A LOADING AND A SWEET ALERT!
  createUser() {
    // Update this.newUser with form values
    this.newUser.userRegistration.username =
      this.personalInfoForm.get('username')?.value;
    this.newUser.userRegistration.firstName =
      this.personalInfoForm.get('firstName')?.value;
    this.newUser.userRegistration.lastName =
      this.personalInfoForm.get('lastName')?.value;
    // Set credentials value
    this.newUser.userRegistration.credentials[0].value =
      this.personalInfoForm.get('tempPassword')?.value;

    this.newUser.userRegistration.email =
      this.contactInfoForm.get('email')?.value;
    this.newUser.userRegistration.attributes.phoneNumber =
      this.contactInfoForm.get('phoneNumber')?.value.e164Number;

    // Set userRole.id and userRole.name based on the selected role
    const selectedRoleIds = this.rolesAndLanguageForm.get('roles')?.value;

    this.newUser.userRole = this.roles
      .filter((r) => selectedRoleIds?.includes(r.id))
      .map((r) => {
        return {
          name: r.name,
          id: r.id,
        };
      });

    const selectedLocaleId = this.rolesAndLanguageForm.get('locale')?.value;
    const selectedLocale = this.locales.find(
      (locale) => locale.id == selectedLocaleId
    );
    this.newUser.userRegistration.attributes.locale = selectedLocale?.code;

    this.loading = true;

    // Send the request to create a new user
    this.userManagement
      .newUser(this.newUser)
      .subscribe({
        next: (response) => {
          Swal.fire({
            text: 'New user created successfully!',
            toast: true,
            position: 'bottom-end',
            showCancelButton: false,
            showConfirmButton: false,
            color: 'white',
            background: '#0d9488',
            timer: 3000,
          });

          this.router.navigate(['ehr/userManagement']);
        },
        error: (err) => {
          Swal.fire({
            text: `Unable to create user! ${err.error.message}`,
            toast: true,
            position: 'bottom-end',
            showCancelButton: false,
            showConfirmButton: false,
            color: 'white',
            background: '#ff6969',
            timer: 3000,
          });
          console.error('Error creating new user:', err);
        },
      })
      .add(() => {
        // Hide loading indicator
        this.loading = false;
      });
  }

  nextStep(stepper: MatStepper, stepIncrement: number): void {
    const currentStep = stepper.selectedIndex;
    const nextStep = currentStep + stepIncrement;

    // Get the form of the current step
    let currentStepForm: FormGroup | undefined;
    switch (currentStep) {
      case 0:
        currentStepForm = this.personalInfoForm;
        break;
      case 1:
        currentStepForm = this.contactInfoForm;
        break;
      case 2:
        currentStepForm = this.rolesAndLanguageForm;
        break;
      default:
        break;
    }

    // Mark the form controls as touched
    if (currentStepForm) {
      Object.keys(currentStepForm.controls).forEach((controlName) => {
        const control = currentStepForm?.get(controlName);
        control?.markAsTouched();
      });

      // Check if the form is valid before proceeding to the next step
      if (nextStep >= 0 && nextStep < stepper._steps.length) {
        if (currentStepForm.valid) {
          stepper.selectedIndex = nextStep;
        } else {
          // Handle validation errors
          if (stepIncrement === -1 && stepper.selectedIndex != 0) {
            //Go back on second and after
            stepper.selectedIndex = stepper.selectedIndex + stepIncrement;
          } else {
            Swal.fire({
              text: 'Fill all the required data to continue!',
              toast: true,
              position: 'bottom-end',
              showCancelButton: false,
              showConfirmButton: false,
              color: 'white',
              background: '#ff6969',
              timer: 3000,
            });
          }
          console.log('Form contains validation errors.');
        }
      } else if (nextStep < 0 && currentStep > 0) {
        stepper.selectedIndex = nextStep;
      } else {
        // Handle validation errors or edge cases
        console.log('Form contains validation errors or invalid step.');
      }
    }
  }

  // NEW SUBSCRIBE SYNTAX
  fetchDropdownData() {
    // Fetch dropdown data when the component initializes
    this.userManagement.getDropdownData().subscribe({
      next: (data) => {
        this.roles = data.data.roles.map((role: any) => ({
          ...role,
          name: this.translate.instant(role.name),
        }));
        this.locales = data.data.locale;
        //Initialize default values here if needed !
        // this.newUser.userRole.id = this.roles.length > 0 ? this.roles[0].id : '';
        // this.newUser.userRole.name = this.roles.length > 0 ? this.roles[0].name : '';
        // this.newUser.userRegistration.attributes.locale = this.locales.length > 0 ? this.locales[0].id : '';
      },
      error: (err) => {
        console.error('Error fetching dropdown data:', err);
      },
    });
  }

  cancelCreate() {
    this.router.navigate(['ehr/userManagement']);
  }

  protected readonly oncancel = oncancel;

  getPhoneNumberControl(): FormControl<string | null> {
    return (
      (this.contactInfoForm.get('phoneNumber') as FormControl<string | null>) ||
      new FormControl<string | null>(null)
    );
  }
}
