import { Component } from '@angular/core';
import Swal from 'sweetalert2';
import { IGetList } from 'src/app/interfaces/Get/IGetList';
import { Subject, takeUntil } from 'rxjs';
import { IMedicalDeviceDetailed } from '../../../../../../interfaces/MedicalDevice/IMedicalDeviceDetailed';
import { MedicalDeviceService } from '../../../../../../services/medicalDevice.service';
import { MedicalDeviceAddEditComponent } from './medical-device-add-edit/medical-device-add-edit.component';
import { MedicalDeviceExpandComponent } from './medical-device-expand/medical-device-expand.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-medical-device',
  templateUrl: './medical-device.component.html',
  styleUrls: ['./medical-device.component.css'],
})
export class MedicalDeviceComponent {
  public addComp = MedicalDeviceAddEditComponent;
  public expandComp = MedicalDeviceExpandComponent;

  private destroy$ = new Subject<void>();

  medicalDevices?: IGetList<IMedicalDeviceDetailed>;
  isLoading: boolean = false;
  isNoKnownLoading: boolean = false;

  constructor(
    private readonly medicalDeviceService: MedicalDeviceService,
    private readonly translate: TranslateService
  ) {}

  ngOnInit() {
    this.medicalDeviceService.medicalDeviceLoadingSub
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: boolean) => {
        this.isLoading = data;
      });

    this.medicalDeviceService.medicalDeviceNoKnownLoadingSub
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: boolean) => {
        this.isNoKnownLoading = data;
      });

    this.medicalDeviceService.medicalDeviceDataSub
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: IGetList<IMedicalDeviceDetailed>) => {
        this.medicalDevices = data;
      });

    this.medicalDeviceService.getData();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  openModalFun(item?: IMedicalDeviceDetailed) {
    this.medicalDeviceService.medicalDeviceModalSub.next(true);
    this.medicalDeviceService.medicalDeviceEditSub.next(item);
  }

  deleteFun(medicalDeviceId: number) {
    Swal.fire({
      text: `${this.translate.instant(
        'deletePlaceholder2'
      )} ${this.translate.instant('Medical Device')}?`,
      showDenyButton: false,
      showCancelButton: true,
      cancelButtonText: `${this.translate.instant('Cancel')}`,
      confirmButtonText: `${this.translate.instant('Ok')}`,
    }).then((result) => {
      if (result.isConfirmed) {
        this.medicalDeviceService
          .deleteMedicalDevice(medicalDeviceId.toString())
          .subscribe({
            next: (d) => {
              Swal.fire({
                text: 'Medical device deleted successfully!',
                toast: true,
                position: 'bottom-end',
                showCancelButton: false,
                showConfirmButton: false,
                color: 'white',
                background: '#0d9488',
                timer: 3000,
              });

              this.medicalDeviceService.getData();
            },
            error: (err) => {
              Swal.fire({
                text: 'Unable to delete medical device!',
                toast: true,
                position: 'bottom-end',
                showCancelButton: false,
                showConfirmButton: false,
                color: 'white',
                background: '#ff6969',
                timer: 3000,
              });
              console.log(err);
            },
          });
      } else if (result.isDenied) {
        Swal.fire('Medical device is not deleted', '', 'info');
      }
    });
  }

  handleToggleFun(preview: boolean, switchValue: boolean, unknownid?: string) {
    if (!preview) {
      if (switchValue) {
        this.medicalDeviceService.medicalDeviceNoKnownLoadingSub.next(true);
        this.medicalDeviceService.addNoKnownMedicalDevice().subscribe({
          next: () => {
            Swal.fire({
              text: 'No known Medical Devices set successfully!',
              toast: true,
              position: 'bottom-end',
              showCancelButton: false,
              showConfirmButton: false,
              color: 'white',
              background: '#0d9488',
              timer: 3000,
            });

            this.medicalDeviceService.getData();
          },
          error: (err) => {
            Swal.fire({
              text: 'Unable to set No Known Medical Devices!',
              toast: true,
              position: 'bottom-end',
              showCancelButton: false,
              showConfirmButton: false,
              color: 'white',
              background: '#ff6969',
              timer: 3000,
            });
            console.error('Error setting No Known Medical Devices:', err);
          },
        });
      } else {
        if (unknownid) {
          this.medicalDeviceService.medicalDeviceNoKnownLoadingSub.next(true);
          this.medicalDeviceService
            .deleteNoKnownMedicalDevice(unknownid)
            .subscribe({
              next: () => {
                Swal.fire({
                  text: 'No known Medical Devices unset successfully!',
                  toast: true,
                  position: 'bottom-end',
                  showCancelButton: false,
                  showConfirmButton: false,
                  color: 'white',
                  background: '#0d9488',
                  timer: 3000,
                });

                this.medicalDeviceService.getData();
              },
              error: (err) => {
                Swal.fire({
                  text: 'Unable to unset No Known Medical Devices!',
                  toast: true,
                  position: 'bottom-end',
                  showCancelButton: false,
                  showConfirmButton: false,
                  color: 'white',
                  background: '#ff6969',
                  timer: 3000,
                });
                console.error('Error unsetting No Known Medical Devices:', err);
              },
            });
        }
      }
    }
  }

  expandSet = new Set<number>();
  onExpandChange(id: number, checked: boolean): void {
    if (checked) {
      this.expandSet.add(id);
    } else {
      this.expandSet.delete(id);
    }
  }
}
