<div *ngIf="!loading" class="new-user-container">
  <h2 class="titleBar">{{ "Create New User" | translate }}</h2>

  <form (ngSubmit)="createUser()">
    <mat-vertical-stepper #stepper>
      <mat-step
        [label]="'1. ' + ('User Information' | translate)"
        [formGroup]="personalInfoForm"
      >
        <div class="row-fields">
          <div>
            <div fxLayout="row" fxLayoutAlign="center center" class="label-row">
              <div fxLayout="col" fxFlex="50" class="add-patient-col">
                {{ "First name" | translate }}
                <span class="required-asterisk">*</span>
              </div>
              <div fxLayout="col" fxFlex="50" class="add-patient-col">
                {{ "Last name" | translate }}
                <span class="required-asterisk">*</span>
              </div>
            </div>
            <div
              fxLayout="row"
              fxLayoutAlign="center center"
              class="add-patient-row"
            >
              <div fxLayout="col" fxFlex="50" class="add-patient-col">
                <input
                  class="add-patient-input"
                  [placeholder]="'First name' | translate"
                  formControlName="firstName"
                />
              </div>
              <div fxLayout="col" fxFlex="50" class="add-patient-col">
                <input
                  class="add-patient-input"
                  [placeholder]="'Last name' | translate"
                  formControlName="lastName"
                />
              </div>
            </div>
          </div>

          <div>
            <div fxLayout="row" fxLayoutAlign="center center" class="label-row">
              <div fxLayout="col" fxFlex="50" class="add-patient-col">
                {{ "Username" | translate }}
                <span class="required-asterisk">*</span>
              </div>
              <div fxLayout="col" fxFlex="50" class="add-patient-col">
                {{ "Temporary Password" | translate }}
                <span class="required-asterisk">*</span>
              </div>
            </div>
            <div
              fxLayout="row"
              fxLayoutAlign="center center"
              class="add-patient-row"
            >
              <div fxLayout="col" fxFlex="50" class="add-patient-col">
                <input
                  class="add-patient-input"
                  [placeholder]="'Username' | translate"
                  formControlName="username"
                />
              </div>
              <div fxLayout="col" fxFlex="50" class="add-patient-col">
                <input
                  class="add-patient-input"
                  [placeholder]="'Temporary Password' | translate"
                  formControlName="tempPassword"
                />
              </div>
            </div>
          </div>
        </div>

        <div fxLayout="row" fxLayoutAlign="end center">
          <button
            class="add-patient-button"
            (click)="nextStep(stepper, 1)"
            type="button"
          >
            {{ "Next" | translate }}
          </button>
        </div>
      </mat-step>

      <mat-step
        [label]="'2. ' + ('Contact Information' | translate)"
        [formGroup]="contactInfoForm"
      >
        <div class="row-fields">
          <div>
            <div fxLayout="row" fxLayoutAlign="start" class="label-row">
              <div fxLayout="col" fxFlex="50" class="add-patient-col">
                {{ "Email" | translate }}
                <span class="required-asterisk">*</span>
              </div>
            </div>
            <div fxLayout="row" fxLayoutAlign="start" class="add-patient-row">
              <div fxLayout="col" fxFlex="50" class="add-patient-col">
                <input
                  id="email"
                  class="add-patient-input"
                  [placeholder]="'Email' | translate"
                  formControlName="email"
                />
              </div>
            </div>
          </div>

          <div>
            <div fxLayout="row" fxLayoutAlign="start" class="label-row">
              <div fxLayout="col" fxFlex="50" class="add-patient-col">
                {{ "Phone Number" | translate }}
                <span class="required-asterisk">*</span>
              </div>
            </div>
            <div fxLayout="row" fxLayoutAlign="start" class="add-patient-row">
              <div fxLayout="col" fxFlex="50" class="add-patient-col">
                <ngx-intl-tel-input
                  [cssClass]="'custom'"
                  [preferredCountries]="[CountryISO.Cyprus, CountryISO.Greece]"
                  [enableAutoCountrySelect]="false"
                  [enablePlaceholder]="false"
                  [searchCountryFlag]="true"
                  [searchCountryField]="[
                    SearchCountryField.Iso2,
                    SearchCountryField.Name
                  ]"
                  [selectFirstCountry]="false"
                  [selectedCountryISO]="CountryISO.Cyprus"
                  [maxLength]="15"
                  [phoneValidation]="true"
                  [separateDialCode]="true"
                  name="phone"
                  formControlName="phoneNumber"
                ></ngx-intl-tel-input>
              </div>
            </div>
          </div>
        </div>

        <div fxLayout="row" fxLayoutAlign="end center">
          <button
            class="back-button"
            (click)="nextStep(stepper, -1)"
            type="button"
          >
            Back
          </button>
          <button
            class="add-patient-button btn-margin"
            (click)="nextStep(stepper, 1)"
            type="button"
          >
            {{ "Next" | translate }}
          </button>
        </div>
      </mat-step>

      <mat-step
        [label]="'3. ' + ('Roles and Language' | translate)"
        [formGroup]="rolesAndLanguageForm"
      >
        <div class="row-fields">
          <div
            fxLayout="row"
            fxLayoutAlign="center center"
            class="add-patient-row"
          >
            <div fxLayout="col" fxFlex="50" class="multiselector">
              <nz-select
                nzMode="multiple"
                class=""
                style="width: 100%"
                formControlName="roles"
                nzPlaceHolder="{{ 'Roles' | translate }}"
              >
                <option disabled selected>{{ "Role" | translate }}</option>
                <ng-container *ngFor="let role of roles">
                  <nz-option
                    [nzLabel]="role.name"
                    [nzValue]="role.id"
                  ></nz-option>
                </ng-container>
              </nz-select>
            </div>

            <div fxLayout="col" fxFlex="50" class="add-patient-col">
              <select class="add-patient-input" formControlName="locale">
                <option value="" disabled selected>
                  {{ "Language" | translate }}
                </option>
                <ng-container *ngFor="let locale of locales">
                  <option [value]="locale.id">{{ locale.name }}</option>
                </ng-container>
              </select>
            </div>
          </div>
        </div>

        <div fxLayout="row" fxLayoutAlign="end center">
          <button class="cancel-button" (click)="cancelCreate()">
            {{ "Cancel" | translate }}
          </button>
          <button
            class="back-button btn-margin"
            (click)="nextStep(stepper, -1)"
            type="button"
          >
            Back
          </button>
          <button
            class="add-patient-button btn-margin"
            [class.disabled-button]="!rolesAndLanguageForm.valid"
            [disabled]="!rolesAndLanguageForm.valid"
            type="submit"
          >
            {{ "Create User" | translate }}
          </button>
        </div>
      </mat-step>
    </mat-vertical-stepper>
  </form>
</div>

<div *ngIf="loading" class="loading-container">
  <!-- Centered loading spinner -->
  <div class="loading-text">
    <p>{{ "Loading..." | translate }}</p>
  </div>
  <mat-spinner></mat-spinner>
</div>
