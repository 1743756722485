import { Component } from '@angular/core';
import { Model } from 'survey-core';
import { inpatientJson } from './jsons/inpatientJson';
import { outpatientJson } from './jsons/outpatientJson';
import { EpisodeOfCareService } from 'src/app/services/episodeOfCare.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-social-history-questonaire',
  templateUrl: './social-history-questonaire.component.html',
  styleUrl: './social-history-questonaire.component.css',
})
export class SocialHistoryQuestonaireComponent {
  model: Model = new Model();

  constructor(
    private readonly eocService: EpisodeOfCareService,
    private readonly router: Router
  ) {}
  isInpatientView() {
    return (
      !(
        this.eocService.currentEocId() === null ||
        this.eocService.currentEocId() === ''
      ) && this.router.url.includes('inPatientView')
    );
  }
  ngOnInit() {
    let survey: Model = new Model();
    this.isInpatientView()
      ? (survey = new Model(inpatientJson))
      : (survey = new Model(outpatientJson));

    survey.onComplete.add((sender, options) => {
      console.log(JSON.stringify(sender.data, null, 3));
    });

    this.model = survey;
  }
}
