<nz-modal #modalRef nzSize [(nzVisible)]="isVisible"
    [nzTitle]="((isEdit ? 'Edit' : 'Add') + ' Medical Device') | translate" (nzOnCancel)="handleCancel()"
    nzCancelText="Cancel" nzOkText="Save" nzOkType="primary" [nzFooter]="null" class="custom-modal-class">
    <ng-container *nzModalContent>
        <form [formGroup]="medicalDeviceForm" (ngSubmit)="submitForm()"
            [ngClass]="{'allergies-submitted': medicalDeviceSubmitted, 'allergies-not-submitted': !medicalDeviceSubmitted}">
            <div class="form-row" fxLayout="row" fxLayoutAlign="start center">
                <div fxLayout="col" fxLayoutAlign="start center" fxFlex="30">
                    <label>{{"Medical Device" | translate}}</label>
                </div>
                <div fxLayout="col" fxLayoutAlign="start center" fxFlex="70">
                    <nz-select class="inputs allergy" nzShowSearch nzAllowClear [nzPlaceHolder]="'Medical Device' | translate"
                        formControlName="medicalDeviceValue" [nzLoading]="isLoading" [nzOptions]="devices"
                        nzNoAnimation>
                        <!-- <ng-container *ngFor="let et of medicalDeviceDropdown?.medicalDevice">
                            <nz-option [nzLabel]="et.name" [nzValue]="et.id"></nz-option>
                        </ng-container> -->
                    </nz-select>
                </div>
            </div>

            <div class="form-row" fxLayout="row" fxLayoutAlign="start center">
                <div fxLayout="col" fxLayoutAlign="start center" fxFlex="30">
                    <label>{{"Device Action" | translate}}</label>
                </div>
                <div fxLayout="col" fxLayoutAlign="start center" fxFlex="70">
                    <nz-select class="inputs allergy" nzShowSearch nzAllowClear [nzPlaceHolder]="'Medical Device Action ' | translate"
                        formControlName="medicalDeviceActionValue" [nzLoading]="isLoading">
                        <ng-container *ngFor="let et of medicalDeviceDropdown?.deviceAction">
                            <nz-option [nzLabel]="et.name" [nzValue]="et.id"></nz-option>
                        </ng-container>
                    </nz-select>
                </div>
            </div>


            <div class="form-row" fxLayout="row" fxLayoutAlign="start center">
                <div fxLayout="col" fxLayoutAlign="start center" fxFlex="30">
                    <label>{{"Onset Date" | translate}}</label>
                </div>
                <div fxLayout="col" fxLayoutAlign="start center" fxFlex="70">
                    <input nz-input class="inputs allergy" type="date" [placeholder]="'Onset Date' | translate"
                        formControlName="onSetDateValue"
                        [max]="this?.medicalDeviceForm?.value?.removalDateValue|| getCurrentDate()" />
                </div>
            </div>

            <div class="form-row" fxLayout="row" fxLayoutAlign="start center">
                <div fxLayout="col" fxLayoutAlign="start center" fxFlex="30">
                    <label>{{"Removal Date" | translate}}</label><span class="optional">{{"(optional)" |
                        translate}}</span>
                </div>
                <div fxLayout="col" fxLayoutAlign="start center" fxFlex="70">
                    <input nz-input class="inputs allergy" type="date" [placeholder]="'Removal Date' | translate"
                        formControlName="removalDateValue" [min]="this?.medicalDeviceForm?.value?.onSetDateValue"
                        [max]="getCurrentDate()" />
                </div>
            </div>


            <div class="form-row" fxLayout="row" fxLayoutAlign="start center">
                <div fxLayout="col" fxLayoutAlign="start center" fxFlex="30">
                    <label>{{"Description" | translate}}</label>
                </div>
                <div fxLayout="col" fxLayoutAlign="start center" fxFlex="70">
                    <input nz-input class="inputs allergy" type="text" [placeholder]="'Description ...' | translate"
                        formControlName="descriptionValue" />
                </div>
            </div>

            <div fxLayout="row" fxLayoutAlign="end" class="footer">
                <button nz-button nzPrimary class="save-btn" type="submit" [nzLoading]="isLoading"
                    [disabled]="isLoading">{{"Save" | translate}}</button>
                <button nz-button nzDanger class="cancel-btn" type="button" (click)="handleCancel()"
                    [disabled]="isLoading">{{"Cancel" | translate}}</button>
            </div>
        </form>
    </ng-container>
</nz-modal>