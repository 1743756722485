import { Component, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { IAllergyDetailed } from 'src/app/interfaces/Detailed/IAllergyDetailed';
import { IGetList } from 'src/app/interfaces/Get/IGetList';
import { IWorkspacePatient } from 'src/app/interfaces/Workspace/IWorkspacePatient';
import { PatientService } from 'src/app/services/patient.service';
import { WorkspaceService } from 'src/app/services/workspace.service';
import { AllergiesExpandComponent } from '../../patients/components/alerts-section/allergies/allergies-expand/allergies-expand.component';
import { IWorkspacePatientSearch } from 'src/app/interfaces/Workspace/IWorkspacePatientSearch';
import moment from 'moment';

@Component({
  selector: 'app-my-patients',
  templateUrl: './my-patients.component.html',
  styleUrl: './my-patients.component.css',
})
export class MyPatientsComponent {
  // @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(MatPaginator, { static: false }) paginator!: MatPaginator;

  public selectedPatientID?: string;

  public allergiesExpandComp = AllergiesExpandComponent;

  public isLoading = false;

  public profilePicture: Record<number, string> = {};

  public data: MatTableDataSource<IWorkspacePatient> =
    new MatTableDataSource<IWorkspacePatient>([]);

  public totalRecords: number = 0;

  public displayedColumns: string[] = ['profile', 'age'];

  public allergies: IGetList<IAllergyDetailed> = {};
  public problems: IGetList<IAllergyDetailed> = {};
  public medications: IGetList<IAllergyDetailed> = {};

  constructor(
    private readonly workspaceService: WorkspaceService,
    private readonly patientService: PatientService
  ) {}

  clearPatientsForm() {
    this.data = new MatTableDataSource<IWorkspacePatient>([]);
    this.data.paginator = this.paginator;
  }

  async pageChange(input?: string) {
    const patientObj: IWorkspacePatientSearch = {};
    if (input) patientObj.searchField = input;

    await this.workspaceService
      .getPatients(
        patientObj,
        this.paginator.pageSize,
        this.paginator.pageIndex + 1
      )
      .subscribe({
        next: (d) => {
          this.data = new MatTableDataSource(
            d?.data?.map((d: IWorkspacePatient) => d)
          );

          this.totalRecords = d.totalRecords || 0;

          d?.data.map((d) => {
            if (d.picturePath) {
              this.patientService
                .getProfilePicture(d.id?.toString()!)
                .subscribe({
                  next: (blob?: Blob) => {
                    if (blob) {
                      const reader = new FileReader();
                      reader.onload = () => {
                        this.profilePicture[d.id!] =
                          reader.result?.toString() || '';
                        return reader.result;
                      };
                      reader.readAsDataURL(blob);
                    }
                  },
                  error: (error) => {
                    console.error('Error fetching profile picture:', error);
                    return '';
                  },
                });
            }
          });

          if (d.totalRecords == 1) {
            this.onPatientClick(d.data[0].id?.toString()!);
          }

          if (d.totalRecords == 0) {
            this.selectedPatientID = undefined;
          }
        },
        error: (err) => {
          console.log(err);
        },
      });
  }

  async searchPatientsForm() {
    this.paginator.pageIndex = 0;

    this.pageChange();
  }

  getCurrentDate() {
    return new Date().toISOString().split('T')[0];
  }

  // TO REMOVE LATER
  ngAfterViewInit() {
    this.data.paginator = this.paginator;
    this.searchPatientsForm();
  }

  downloadPicture(id: number): string | void {
    this.patientService.getProfilePicture(id?.toString()!).subscribe({
      next: (blob?: Blob) => {
        if (blob) {
          const reader = new FileReader();
          reader.onload = () => {
            return reader.result;
          };
          reader.readAsDataURL(blob);
        }
      },
      error: (error) => {
        console.error('Error fetching profile picture:', error);
        return '';
      },
    });
  }

  onPatientClick(id: string) {
    this.selectedPatientID = id?.toString();

    this.isLoading = true;
    this.workspaceService.getQuickInfo(this.selectedPatientID).subscribe({
      next: (d) => {
        this.allergies = d.data?.getAllAllergies;
        this.problems = d.data?.getAllProblems;

        this.medications = d.data.getAllMedications;
        this.medications.getAll =
          d.data?.getAllMedications.getAll?.map((m) => {
            return {
              ...m,
              onSetDate: moment(m.onSetDateTime).format('YYYY-MM-DD'),
              endDate: moment(m.endDateTime).format('YYYY-MM-DD'),
            };
          }) || [];

        console.log(this.medications.getAll);
        this.isLoading = false;
      },
      error: (err) => {
        console.log(err);
      },
    });
  }

  getTableSize() {
    if (window.innerWidth <= 1279) {
      return [5];
    } else {
      return [10];
    }
  }
}
