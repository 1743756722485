import { Component, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { IUserManagement } from '../../../interfaces/IUserManagement';
import { UserManagementService } from '../../../services/userManagement.service';
import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-user-management',
  templateUrl: './user-management.component.html',
  styleUrls: ['./user-management.component.css'],
})
export class UserManagementComponent {
  @ViewChild(MatPaginator, { static: false }) paginator!: MatPaginator;

  constructor(
    private readonly userManagement: UserManagementService,
    private readonly router: Router,
    private readonly translate: TranslateService
  ) {}
  ngOnInit() {
    this.getAllUsers();
  }

  public userManagementData: IUserManagement[] = [];
  public data: MatTableDataSource<IUserManagement> =
    new MatTableDataSource<IUserManagement>([]);

  public displayedColumns: string[] = [
    'firstName',
    'lastName',
    'role',
    'email',
    'phoneNumber',
    'umactions',
  ];

  public firstName: string = '';
  public lastName: string = '';
  public role: string = '';
  public email: string = '';
  public phoneNumber: string = '';

  clearSearchFields(currentField: string) {
    if (currentField !== 'firstName') {
      this.firstName = '';
    }
    if (currentField !== 'lastName') {
      this.lastName = '';
    }

    if (currentField !== 'role') {
      this.role = '';
    }

    if (currentField !== 'email') {
      this.email = '';
    }

    if (currentField !== 'phoneNumber') {
      this.phoneNumber = '';
    }
  }

  firstNameChanged(event: any, currentField: string) {
    this.clearSearchFields(currentField);
    this.firstName = event;
    this.searchUser();
  }
  lastNameChanged(event: any, currentField: string) {
    this.clearSearchFields(currentField);
    this.lastName = event;
    this.searchUser();
  }
  roleChanged(event: any, currentField: string) {
    this.clearSearchFields(currentField);
    this.role = event;
    this.searchUser();
  }
  emailChanged(event: any, currentField: string) {
    this.clearSearchFields(currentField);
    this.email = event;
    this.searchUser();
  }
  phoneNumberChanged(event: any, currentField: string) {
    this.clearSearchFields(currentField);
    this.phoneNumber = event;
    this.searchUser();
  }
  searchUser() {
    let filteredData: IUserManagement[] = this.userManagementData;

    if (this.firstName != '') {
      filteredData = filteredData.filter((user) =>
        user.firstName?.toLowerCase().includes(this.firstName.toLowerCase())
      );
    }

    if (this.lastName != '') {
      filteredData = filteredData.filter((user) =>
        user.lastName?.toLowerCase().includes(this.lastName.toLowerCase())
      );
    }

    if (this.role) {
      filteredData = filteredData.filter((user) =>
        user.roles?.some((role) =>
          role.roleName.toLowerCase().includes(this.role.toLowerCase())
        )
      );
    }

    if (this.email) {
      filteredData = filteredData.filter((user) =>
        user.email?.toLowerCase().includes(this.email.toLowerCase())
      );
    }

    if (this.phoneNumber != '') {
      filteredData = filteredData.filter((user) =>
        user.phoneNumber?.toLowerCase().includes(this.phoneNumber.toLowerCase())
      );
    }

    this.data.data = filteredData;
    this.data.paginator = this.paginator;
  }

  deleteUser(userId: string) {
    // Use SweetAlert for a confirmation dialog
    Swal.fire({
      title: `${this.translate.instant(
        'Delete User?'
      )}`,
      text: `${this.translate.instant(
        'You won\'t be able to revert this user back!'
      )}`,
      icon: 'warning',
      iconColor: '#d33',
      showCancelButton: true,
      confirmButtonColor: '#0d9488',
      cancelButtonColor: '#d33',
      cancelButtonText: `${this.translate.instant('Cancel')}`,
      confirmButtonText: `${this.translate.instant('Delete User')}`,
    }).then((result) => {
      if (result.isConfirmed) {
        this.userManagement.deleteUser(userId).subscribe({
          next: (response) => {
            console.log('User deleted successfully:', response);
            this.getAllUsers();
          },
          error: (err) => {
            console.error('Error deleting user:', err);
          },
        });
        Swal.fire('Deleted!', 'Your user has been deleted.', 'success');
      }
    });
  }

  registerUser() {
    this.router.navigate(['/ehr/userRegistration'], {});
  }
  onEdit(row: IUserManagement) {
    this.router.navigate(['/ehr/userProfile'], {
      queryParams: {
        id: row?.id,
      },
    });
  }

  getAllUsers() {
    this.userManagement.getUsers().subscribe({
      next: (response) => {
        this.data.data = response?.data;
        this.userManagementData = response?.data;
        this.data.paginator = this.paginator; // Reapply paginator
      },
      error: (err) => {
        console.log(err);
      },
    });
  }

  getRoles(element: IUserManagement) {
    return element.roles?.map((r) => r.roleName).join('; ');
  }
}
