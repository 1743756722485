import { Component, ViewChild } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { NzModalModule, NzModalRef } from 'ng-zorro-antd/modal';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NzSelectModule, NzSelectOptionInterface } from 'ng-zorro-antd/select';
import { CommonModule } from '@angular/common';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { TooltipComponent } from '../../../../../../../common/tooltip/tooltip.component';
import { NzIconModule } from 'ng-zorro-antd/icon';
import Swal from 'sweetalert2';
import { Subject, takeUntil } from 'rxjs';
import { VaccinationService } from '../../../../../../../services/vaccination.service';
import { IVaccinationDropdown } from '../../../../../../../interfaces/Vaccination/IVaccinationDropdown';
import { IAddVaccination } from '../../../../../../../interfaces/Vaccination/IAddVaccination';
import { NzNoAnimationModule } from 'ng-zorro-antd/core/no-animation';

@Component({
  standalone: true,
  selector: 'app-vaccination-add-edit',
  templateUrl: './vaccination-add-edit.component.html',
  styleUrls: ['./vaccination-add-edit.component.css'],
  imports: [
    NzModalModule,
    TranslateModule,
    FormsModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    NzSelectModule,
    CommonModule,
    NzButtonModule,
    NzTableModule,
    NzLayoutModule,
    TooltipComponent,
    NzIconModule,
    NzNoAnimationModule,
  ],
})
export class VaccinationAddEditComponent {
  private destroy$ = new Subject<void>();

  vaccinationDropDown: IVaccinationDropdown | undefined = undefined;

  doctors: NzSelectOptionInterface[] = [];

  diseaseTargeted: NzSelectOptionInterface[] = [];

  isVisible = false;
  isLoading = false;

  isEdit = false;
  editVaccinationId?: number = undefined;

  vaccinationSubmitted = false;

  @ViewChild('modalRef', { static: true }) modalRef!: NzModalRef; // Reference to the modal

  vaccinationForm: FormGroup = new FormGroup({});

  constructor(
    private readonly vaccinationService: VaccinationService,
    private readonly formBuilder: FormBuilder
  ) {}

  ngOnInit() {
    this.isLoading = true;
    this.isEdit = false;

    this.vaccinationService.getVaccinationDropdown().subscribe({
      next: (pro) => {
        this.vaccinationDropDown = pro?.data;

        this.diseaseTargeted = this.vaccinationDropDown?.diesesTarget.map(
          (d) => {
            return {
              label: d.name,
              value: d.id,
            };
          }
        );

        this.doctors = this.vaccinationDropDown?.doctors.map((d) => {
          return {
            label: d.fullName,
            value: d.id,
          };
        });

        this.isLoading = false;
      },
      error: (err) => {
        console.log(err);
      },
    });

    this.vaccinationForm = this.formBuilder.group({
      vaccineValue: [null, Validators.required],
      diseaseTargetedValue: [null, Validators.required],
      numSeriesDosesValue: [null, Validators.required],
      medicinalProductNameValue: [null],
      marketingAuthorizationHolderValue: [null],
      batchLotNumberValue: [null, Validators.required],
      descriptionValue: [null, Validators.required],
      nextDateValue: [null],
      countryValue: [null, Validators.required],
      doctorValue: [null, Validators.required],
    });

    this.vaccinationService.vaccinationEditSub
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        // GET DATA FIELDS AND ASSIGN DEFAULT VALUES TO FORM
        if (data) {
          this.isEdit = true;
          this.editVaccinationId = data?.id;

          this.vaccinationForm.patchValue({
            vaccineValue: data.vaccineId,
            diseaseTargetedValue: data.diseaseTargetedId,
            numSeriesDosesValue: data.numSeriesDoses,
            medicinalProductNameValue: data?.medicinalProductName,
            marketingAuthorizationHolderValue:
              data?.marketingAuthorizationHolder,
            batchLotNumberValue: data?.batchLotNumber,
            descriptionValue: data?.description,
            nextDateValue: data?.nextDate,
            countryValue: data?.countryId,
            doctorValue: data?.doctorId,
          });
        } else {
          this.isEdit = false;
          this.editVaccinationId = undefined;

          this.vaccinationSubmitted = false;

          this?.vaccinationForm.reset();
        }
      });

    this.vaccinationService.vaccinationModalSub
      .pipe(takeUntil(this.destroy$))
      .subscribe((showModal) => {
        this.isVisible = showModal;
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();

    this?.modalRef.destroy();
  }

  getCurrentDate() {
    return new Date().toISOString().split('T')[0];
  }

  handleCancel() {
    this.vaccinationForm.reset();

    this.vaccinationService.vaccinationModalSub.next(false);

    this.vaccinationSubmitted = false;

    this.isLoading = false;

    this.modalRef.destroy();
  }

  submitForm() {
    this.isLoading = true;
    this.vaccinationSubmitted = true;

    if (this.vaccinationForm.valid) {
      const vaccinationObj: IAddVaccination = {};
      vaccinationObj.id = this.editVaccinationId;
      vaccinationObj.vaccineId = this.vaccinationForm.value?.vaccineValue;
      vaccinationObj.diseaseTargetedId =
        this.vaccinationForm.value?.diseaseTargetedValue;
      vaccinationObj.numSeriesDoses =
        this.vaccinationForm.value?.numSeriesDosesValue;
      vaccinationObj.medicinalProductName =
        this.vaccinationForm.value?.medicinalProductNameValue;
      vaccinationObj.marketingAuthorizationHolder =
        this.vaccinationForm.value?.marketingAuthorizationHolderValue;
      vaccinationObj.batchLotNumber =
        this.vaccinationForm.value?.batchLotNumberValue;
      vaccinationObj.description = this.vaccinationForm.value?.descriptionValue;
      vaccinationObj.nextDate = this.vaccinationForm.value?.nextDateValue;
      vaccinationObj.countryId = this.vaccinationForm.value?.countryValue;
      vaccinationObj.doctorId = this.vaccinationForm.value?.doctorValue;

      if (this.isEdit) {
        this.vaccinationService.editVaccination(vaccinationObj).subscribe({
          next: () => {
            Swal.fire({
              text: 'Vaccination edited successfully!',
              toast: true,
              position: 'bottom-end',
              showCancelButton: false,
              showConfirmButton: false,
              color: 'white',
              background: '#0d9488',
              timer: 3000,
            });

            this.vaccinationService.getData();

            this.handleCancel();
          },
          error: (err) => {
            Swal.fire({
              text: 'Unable to edit vaccination!',
              toast: true,
              position: 'bottom-end',
              showCancelButton: false,
              showConfirmButton: false,
              color: 'white',
              background: '#ff6969',
              timer: 3000,
            });
            console.log(err);

            this.handleCancel();
          },
        });
      } else {
        this.vaccinationService.addVaccination(vaccinationObj).subscribe({
          next: () => {
            Swal.fire({
              text: 'Vaccination added successfully!',
              toast: true,
              position: 'bottom-end',
              showCancelButton: false,
              showConfirmButton: false,
              color: 'white',
              background: '#0d9488',
              timer: 3000,
            });

            this.vaccinationService.getData();

            this.handleCancel();
          },
          error: (err) => {
            Swal.fire({
              text: 'Unable to add vaccination!',
              toast: true,
              position: 'bottom-end',
              showCancelButton: false,
              showConfirmButton: false,
              color: 'white',
              background: '#ff6969',
              timer: 3000,
            });
            console.log(err);
          },
        });
      }
    } else {
      this.isLoading = false;
    }
  }
}
